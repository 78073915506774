import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
 Text,
 TextInput,
 HelperText,
 IconButton,
 Card,
 useTheme,
 Button,
} from "react-native-paper";
import { View } from "react-native";
import { nanoid } from "nanoid";

export default function MultiTextInput({
 invalidMessage,
 onChange,
 value,
 placeholder,
 isRequired = false,
 icon,
}) {
 const theme = useTheme();

 // criamos localData pois como estamos trabalhando com refs
 // a lista que vem de cima nem sempre estará atualizada corretamente
 const localData = useRef(null);

 const label = useMemo(() => `${placeholder} ${isRequired ? "*" : ""}`, [isRequired, placeholder]);

 // constrola quais dos itens da lista precisa receber um rerender na tela
 const [localRefresh, setLocalRefresh] = useState([]);

 const addItem = useCallback(() => {
  const newValue = [...localData.current];
  newValue.push("");
  onChange(newValue);
  localData.current = newValue;
  setLocalRefresh((r) => [...r, nanoid(7)]);
 }, [onChange]);

 const removeItem = useCallback(
  (index) => {
   const newValue = [...localData.current];
   newValue.splice(index, 1);
   onChange(newValue);
   localData.current = newValue;

   setLocalRefresh((arr) => {
    const nArr = [...arr];
    nArr.splice(index, 1);
    for (let i = index; i < nArr.length; i++) {
     nArr[i] = nanoid(7);
    }
    return nArr;
   });
  },
  [onChange]
 );

 const updateItem = useCallback(
  (val, index) => {
   const newValue = [...localData.current];
   newValue[index] = val;
   onChange(newValue);
   localData.current = newValue;
  },
  [onChange]
 );

 useEffect(() => {
  if (localData.current) return;
  const newData = value.map((val) => val || "");
  localData.current = newData;
  setLocalRefresh(newData.map(() => nanoid(7)));
  onChange(newData);
 }, [value, onChange]);

 if (!localData.current) return <Text style={{ color: "red" }}>Erro #4012</Text>;
 return (
  <View style={{ marginTop: 10 }}>
   <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
    <Text variant="titleMedium">{label}</Text>
    <Button
     //  contentStyle={{ height: 30 }}
     style={{ marginLeft: 10 }}
     icon={icon || ""}
     mode="contained"
     onPress={() => addItem()}
     title="Novo">
     Novo
    </Button>
   </View>

   {!!invalidMessage && (
    <HelperText type="error" visible>
     {invalidMessage}
    </HelperText>
   )}
   <Card mode="elevated" style={{ marginBottom: 20 }}>
    <Card.Content>
     {localData.current.map((val, index) => (
      <View
       key={`${index}_${localRefresh[index]}`}
       style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
       }}>
       <TextInput
        style={{ borderTopEndRadius: 0, borderTopLeftRadius: 0, width: "100%", flex: 1 }}
        dense
        label={`Item ${index + 1}`}
        onChangeText={(newVal) => {
         updateItem(newVal, index);
        }}
        defaultValue={val}
       />
       <IconButton
        style={{ marginLeft: 5 }}
        icon="delete-outline"
        iconColor={theme.colors.error}
        size={20}
        onPress={() => removeItem(index)}
       />
      </View>
     ))}
    </Card.Content>
   </Card>
  </View>
 );
}
