import React, { useEffect, useMemo } from "react";
import useDatabase from "../databases/FirebaseDatabase";
import decideField from "../services/decideField";
import generatePdf from "../services/generatePdf";
import { Linking, Platform, ScrollView, View } from "react-native";
import { shareAsync } from "expo-sharing";
import { printToFileAsync } from "expo-print";
import { Appbar, Button, Card, useTheme } from "react-native-paper";
import useFab from "../components/UserFab";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import generateCalendarUrl from "../services/generateCalendarUrl";

let cached = null;

export default function DetailsView({ navigation, route }) {
 const theme = useTheme();
 const { systemConfig, data, mappedCustomLists, users, removeItem } = useDatabase();
 const { id } = route.params;

 // código para mostrar fab na rota
 const { hideFab } = useFab();
 useEffect(() => {
  const unsubscribe = navigation.addListener("focus", () => {
   hideFab();
  });
  return unsubscribe;
 }, [navigation, hideFab]);

 const mappedFields = useMemo(() => {
  if (cached) return cached;
  const fieldsByCategory = {
   nocat: [], // para campos sem categoria
  };
  Object.keys(systemConfig.fields).forEach((key) => {
   const field = systemConfig.fields[key];
   field.key = key; // jogamos o o identificador do field pra dentro do objeto
   if (!field.category) {
    fieldsByCategory.nocat.push(systemConfig.fields[key]);
   } else {
    if (!fieldsByCategory[field.category]) fieldsByCategory[field.category] = [];
    fieldsByCategory[field.category].push(systemConfig.fields[key]);
   }
  });
  cached = fieldsByCategory;
  return fieldsByCategory;
 }, [systemConfig]);

 if (!data[id]) return null;
 return (
  <>
   <Appbar.Header>
    <Appbar.BackAction
     onPress={() => {
      navigation.goBack();
     }}
    />
    <Appbar.Content title="Detalhes" />
    {!!systemConfig.pdfTemplate && (
     <Appbar.Action
      icon="file-pdf-box"
      onPress={async () => {
       if (Platform.OS === "android" || Platform.OS === "ios")
        window.toast("Estamos criando o seu PDF, aguarde um momento...");

       const html = await generatePdf(systemConfig, data[id]);

       if (Platform.OS === "android" || Platform.OS === "ios") {
        // On iOS/android prints the given html. On web prints the HTML from the current page.
        const print = await printToFileAsync({ html, height: 842 });
        if (!print) return;
        shareAsync(print.uri, { UTI: ".pdf", mimeType: "application/pdf" });
       } else {
        navigation.navigate("PDF", { html });
       }
      }}
     />
    )}
    <Appbar.Action
     icon="pencil"
     onPress={() => {
      navigation.navigate("Editar", {
       id,
      });
     }}
    />
    <Appbar.Action
     color={theme.colors.error}
     icon="delete-forever"
     onPress={() => {
      window.confirmationModal({
       title: "Tem certeza?",
       description: "Excluir um item é uma ação irreversível!",
       positiveLabel: "Excluir",
       positiveCallback: async () => {
        navigation.goBack();
        removeItem(id);
       },
      });
     }}
    />
   </Appbar.Header>
   <ScrollView
    style={{ backgroundColor: theme.colors.background, padding: 15 }}
    showsVerticalScrollIndicator={false}>
    {!!systemConfig.calendarIntegration && data[id][systemConfig.calendarIntegration.dateField] && (
     <View style={{ maxWidth: 1000, marginHorizontal: "auto", width: "100%" }}>
      <Button
       style={{ marginLeft: "auto", marginBottom: 15 }}
       mode="outlined"
       icon={systemConfig.calendarIntegration.icon}
       onPress={() => {
        const url = generateCalendarUrl(systemConfig, data[id]);
        if (url) Linking.openURL(url);
       }}
       title="Agenda">
       {systemConfig.calendarIntegration.btnLabel || "Adicionar à agenda"}
      </Button>
     </View>
    )}

    {Object.keys(mappedFields).map((category, index) => {
     if (mappedFields[category].length === 0) return null;
     return (
      <Card
       mode="contained"
       style={{ marginBottom: 15, maxWidth: 1000, marginHorizontal: "auto", width: "100%" }}
       key={index}>
       <Card.Title title={category === "nocat" ? "Outros" : category} />
       <Card.Content>
        {mappedFields[category].map((field) => {
         return decideField(field, data[id], mappedCustomLists, users, theme);
        })}
       </Card.Content>
      </Card>
     );
    })}
   </ScrollView>
  </>
 );
}
