import React, { useCallback, useEffect, useState } from "react";
import useDatabase from "../databases/FirebaseDatabase";
import logo from "../assets/logo.png";
import { Image, Platform, ScrollView, View } from "react-native";
import { Text, TextInput, Button, HelperText, useTheme } from "react-native-paper";
import useFab from "../components/UserFab";
import { useFocusEffect } from "@react-navigation/native";

export function ClientConnectionForm() {
 const { findClientDatabase, } = useDatabase();
 const [clientKey, setClientKey] = useState("");

 const [loading, setLoading] = useState(false);
 const [errorMessage, setErrorMessage] = useState("");

 const connectToClient = useCallback(async () => {
  if (!clientKey) return;
  setLoading(true);
  try {
   const connected = await findClientDatabase(clientKey);
   if (!connected) setLoading(false);
  } catch (e) {
   console.log(e);
   setErrorMessage(e.message);
   setLoading(false);
  }
 }, [clientKey, findClientDatabase]);

 return (
  <>
   <View
    style={{
     width: "100%",
     display: "flex",
     alignItems: "center",
     justifyContent: "center",
     backgroundColor: "#10A9B9",
     paddingTop: 30,
     paddingBottom: 30,
    }}>
    <Image style={{ width: 150, height: 150 }} alt="Logo" source={logo} />
   </View>
   <ScrollView
    style={{
     padding: 20,
     backgroundColor: "#fff",
     borderTopLeftRadius: 20,
     borderTopRightRadius: 20,
     marginTop: 20,
     maxWidth: 1000,
     width: "100%",
     marginHorizontal: "auto",
    }}>
    <View style={{ padding: 20 }}>
     <View>
      <Text variant="titleLarge" style={{ fontWeight: "500" }}>
       Conecte-se à sua empresa!
      </Text>
      <View style={{ marginTop: 30 }}>
       <View>
        <TextInput
         label="Chave da Empresa"
         onChangeText={(val) => {
          setClientKey(val);
          setErrorMessage("");
         }}
         value={clientKey}
        />
        <HelperText type="info" visible>
         Insira a chave da sua empresa para se conectar ao sistema
        </HelperText>
       </View>
      </View>
      <Button
       loading={loading}
       disabled={loading}
       title="CONECTAR"
       mode="contained"
       style={{ marginTop: 20, backgroundColor: "#ED6876"}}
       onPress={connectToClient}>
       CONECTAR
      </Button>
      <HelperText style={{ fontSize: 16 }} type="error" visible={errorMessage}>
       {errorMessage}
      </HelperText>
     </View>
    </View>
   </ScrollView>
  </>
 );
}

export function SignInForm() {
 const theme = useTheme();
 const { logoUrl, signIn, forgotPassword, isConnected } = useDatabase();

 const [user, setUser] = useState("");
 const [pass, setPass] = useState("");
 const [showPass, setShowPass] = useState(false);

 const [loading, setLoading] = useState(false);
 const [errorMessage, setErrorMessage] = useState("");

 return (
  <>
   <>
    <View
     style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: isConnected ? "#2e2e2e" : "#10A9B9",
      paddingTop: 30,
      paddingBottom: 30,
      marginTop: 30,
     }}>
     <View style={{ padding: 20, backgroundColor: "#fff", borderRadius: 10 }}>
      <Image
       style={{ width: 150, height: 150 }}
       resizeMode="contain"
       alt="Logo"
       source={{
        uri: logoUrl,
       }}
      />
     </View>
    </View>
    <ScrollView
     style={{
      padding: 20,
      backgroundColor: theme.colors.background,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      maxWidth: 1000,
      width: "100%",
      marginHorizontal: "auto",
     }}>
     <View style={{ padding: 20 }}>
      <View>
       <Text variant="titleLarge" style={{ fontWeight: "500" }}>
        Faça seu login para continuar!
       </Text>
       <View style={{ marginTop: 30 }}>
        <View>
         <TextInput
          label="Email"
          onChangeText={(val) => {
           setUser(val);
           setErrorMessage("");
          }}
          autoCapitalize="none"
          autoCorrect={false}
          keyboardType={Platform.OS === "android" ? "visible-password" : "email-address"} // foi o único jeito que eu encontrei de não auto completar no meu celular (Bug?)
          value={user}
         />
         <TextInput
          style={{ marginTop: 20 }}
          label="Senha"
          onChangeText={(val) => {
           setPass(val);
           setErrorMessage("");
          }}
          secureTextEntry={!showPass}
          autoCapitalize="none"
          autoCorrect={false}
          right={<TextInput.Icon onPress={() => setShowPass((o) => !o)} icon="eye" />}
          value={pass}
         />
         <Button
          style={{ marginLeft: "auto", marginTop: 5 }}
          onPress={() => {
           if (!user) {
            setErrorMessage("Digite um email válido para recuperar sua senha");
            return;
           }

           setErrorMessage("");
           setLoading(true);
           forgotPassword(user)
            .then(() => {
             ("Um email de recuperação foi enviado para o endereço digitado!");
            })
            .catch((e) => {
             if (e.code === "auth/invalid-email") setErrorMessage("Email digitado é inválido");
             else setErrorMessage(e.message);
            })
            .finally(() => setLoading(false));
          }}>
          Esqueceu sua senha?
         </Button>
        </View>
       </View>

       <Button
        loading={loading}
        disabled={loading}
        title="ENTRAR"
        mode="contained"
        style={{ marginTop: 10 }}
        onPress={() => {
         setLoading(true);
         signIn(user, pass).then((data) => {
          setLoading(false);
          if (!data.success) setErrorMessage(data.message);
         });
        }}>
        ENTRAR
       </Button>
       <HelperText style={{ fontSize: 16 }} type="error" visible={errorMessage}>
        {errorMessage}
       </HelperText>
      </View>
     </View>
    </ScrollView>
   </>
  </>
 );
}

export default function LoginView({ navigation }) {
 const { isConnected } = useDatabase();

 // código para mostrar fab na rota
 const { hideFab } = useFab();
 useEffect(() => {
  const unsubscribe = navigation.addListener("focus", () => {
   hideFab();
  });
  return unsubscribe;
 }, [navigation, hideFab]);

 return (
  <View style={{ backgroundColor: isConnected ? "#2e2e2e" : "#10A9B9", height: "100%" }}>
   {isConnected ? <SignInForm /> : <ClientConnectionForm />}
  </View>
 );
}
