import { useMemo, useState } from "react";
import useDatabase from "../databases/FirebaseDatabase";
import { Menu, HelperText, TextInput } from "react-native-paper";
import { Platform, Pressable } from "react-native";

export default function UserInput({
 onChange,
 value,
 placeholder,
 isRequired = false,
 invalidMessage,
 icon,
}) {
 const [open, setOpen] = useState(false);
 const { users } = useDatabase();
 const [localValue, setLocalValue] = useState(users[value]?.email);

 const mappedUsers = useMemo(() => {
  return Object.keys(users).map((key, index) => (
   <Menu.Item
    key={index}
    title={users[key].email}
    onPress={() => {
     onChange(key);
     setLocalValue(users[key].email);
     setOpen(false);
    }}
   />
  ));
 }, [users, onChange]);

 const label = useMemo(() => `${placeholder} ${isRequired ? "*" : ""}`, [isRequired, placeholder]);
 const parsedIcon = useMemo(
  () => (icon ? <TextInput.Icon icon={icon} onPress={() => setOpen(true)} /> : null),
  [icon]
 );

 return (
  <>
   <Menu
    visible={open}
    onDismiss={() => setOpen(false)}
    anchor={
     <Pressable onPress={() => setOpen(true)}>
      <TextInput
       outlineStyle={{ ...(Platform.OS === "web" && { marginTop: "2px" }) }}
       mode="outlined"
       error={!!invalidMessage}
       focusable={false}
       label={label}
       left={parsedIcon}
       value={localValue || ""}
       right={<TextInput.Icon icon="chevron-down" onPress={() => setOpen(true)} />}
       editable={false}
      />
     </Pressable>
    }>
    {mappedUsers}
   </Menu>
   <HelperText type="error" visible={!!invalidMessage}>
    {invalidMessage}
   </HelperText>
  </>
 );
}
