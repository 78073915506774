import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import useDatabase from "../databases/FirebaseDatabase";

const FileManagerContext = createContext({});

export function FileManager({ children }) {
 const { deleteUpload, getLocalStorage, saveLocalStorage, isConnected, upload } = useDatabase();

 const unusedUploadsDeleted = useRef(false); // usado para saber se já deletamos os uploads que estavam em localStorage ao abrir o app
 const cofirmationQueue = useRef([]); // contém os ids dos uploads que foram realizados durante a sessao do usuario
 const deleteQueue = useRef([]); // contem os ids dos que precisarão ser excluidos em algum momento
 const [isUploading, setIsUploading] = useState(0); // se for > 0, quer dizer que alguns uploads estao em andamento

 // Assim que o usuario entrar, iremos verificar se existe algum upload
 // que ficou em localStorage. Isso geralemnte acontece se o usuario estiver editando/criando um item,
 // fazer alguns uploads, e fechar o app no meio da edição. Se isso acontecer, iremos excluir os uploads
 useEffect(() => {
  if (
   !unusedUploadsDeleted.current &&
   isConnected &&
   getLocalStorage &&
   deleteUpload &&
   saveLocalStorage
  ) {
   unusedUploadsDeleted.current = true;
   getLocalStorage("unusedUploads").then((value) => {
    if (value) {
     const uploads = value.split(",");
     uploads.forEach((id) => {
      console.log("Removendo ", id);
      deleteUpload(id);
     });
     saveLocalStorage("unusedUploads", "");
    }
   });
  }
 }, [deleteUpload, getLocalStorage, isConnected, saveLocalStorage]);

 const uploadFile = useCallback(
  async (uri) => {
   try {
    setIsUploading((old) => old + 1);
    const result = await upload(uri);
    cofirmationQueue.current.push(result.id);
    setIsUploading((old) => old - 1);
    saveLocalStorage("unusedUploads", cofirmationQueue.current.join(","));
    console.log(`Upload ${result.id} realizado com sucesso`);
    return result;
   } catch (e) {
    setIsUploading((old) => old - 1);
    throw e;
   }
  },
  [upload, saveLocalStorage]
 );

 // Usado quando um usuário salvar o item
 const confirmUploads = useCallback(async () => {
  console.log(`Confirmando Uploads`);

  // Como confirmamos os uploads, iremos remover tudo que que está no cofirmationQueue
  cofirmationQueue.current = [];
  saveLocalStorage("unusedUploads", "");

  // Tudo que foi adicionado à deleteQueue será excluído
  deleteQueue.current.forEach((id) => {
   console.log("Removendo ", id);
   deleteUpload(id);
  });
  deleteQueue.current = [];
 }, [saveLocalStorage, deleteUpload]);

 const cancelUploads = useCallback(async () => {
  console.log(`Cancelando Uploads`);

  // Como cancelamos os uploads, tudo que está em cofirmationQueue deve ser removido
  cofirmationQueue.current.forEach((id) => {
   console.log("Removendo ", id);
   deleteUpload(id);
  });
  saveLocalStorage("unusedUploads", "");

  // Limpamos a deleteQueue
  deleteQueue.current = [];
 }, [deleteUpload, saveLocalStorage]);

 // Irá adicionar um item à fila de exclusão.
 // Usando quando um item já tinha sido adicionado e salvo em um momento anterior,
 // mas agora o usuario pretente removê-lo. Para evitar de removermos o item antes do usuario salvar,
 // iremos adicionar à essa fila, e somente excluir a fila quando o usuário salvar
 const addToDeleteQueue = useCallback((id) => {
  console.log(`Adicionando ${id} à fila de exclusão`);
  deleteQueue.current.push(id);
 }, []);

 return (
  <FileManagerContext.Provider
   value={{ addToDeleteQueue, cancelUploads, confirmUploads, uploadFile, isUploading }}>
   {children}
  </FileManagerContext.Provider>
 );
}

export default function useFileManager() {
 return useContext(FileManagerContext);
}
