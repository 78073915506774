import { shareAsync } from "expo-sharing";
import { useCallback, useEffect, useState } from "react";
import { Image, Platform, Pressable, View } from "react-native";
import { Portal } from "react-native-paper";
import useFileDownloader from "../hooks/useFileDownloader";

export default function FullScreenImage() {
 const { downloadFile } = useFileDownloader();
 const [visible, setVisible] = useState(false);
 const [url, setUrl] = useState({});

 const onDismiss = useCallback(() => setVisible(false), []);

 useEffect(() => {
  window.showFullScreenImage = (imageUrl) => {
   setVisible(true);
   setUrl(imageUrl);
  };
 }, []);

 return (
  <Portal>
   {visible && (
    <View
     style={{
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
     }}>
     <Pressable
      style={{
       width: "100%",
       height: "100%",
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
      }}
      onLongPress={async () => {
       onDismiss();
       if (Platform.OS === "web") return;
       window.toast("Preparando imagem para compartilhamento...");
       const uri = await downloadFile(url, `${new Date().getTime()}.png`);
       shareAsync(uri, { UTI: ".png", mimeType: "image/png" });
      }}
      onPress={onDismiss}>
      <View style={{ width: "90%", height: "90%", margin: "auto", backgroundColor: "white" }}>
       <Image
        resizeMode="contain"
        key={url}
        alt="Indisponível"
        style={{
         width: "100%",
         height: "100%",
        }}
        source={{ uri: url }}
       />
      </View>
     </Pressable>
    </View>
   )}
  </Portal>
 );
}
