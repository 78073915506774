import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { WebView } from "react-native-webview";
import useDatabase from "../databases/FirebaseDatabase";
import { Linking, Platform, ScrollView, TextInput } from "react-native";
import { useTheme, Appbar, Portal, Dialog, Button, Text, Card } from "react-native-paper";

export default function InfoView({ navigation }) {
 const theme = useTheme();
 const { isAdmin, info, updateInfo } = useDatabase();
 const ref = useRef(null);
 const webviewRef = useRef(null);
 const [localInfo, setLocalInfo] = useState("");
 const [saving, setSaving] = useState(false);
 const [editing, setEditing] = useState(false);

 const [helperOpen, setHelperOpen] = useState(false);
 const toggleHelper = useCallback(() => setHelperOpen((o) => !o), []);

 useEffect(() => {
  if (ref.current && ref.current.style) {
   ref.current.style.height = "1px";
   ref.current.style.height = ref.current.scrollHeight + "px";
  }
 }, [localInfo]);

 const topActions = useMemo(() => {
  if (!isAdmin) return null;
  else if (editing)
   return (
    <>
     <Appbar.Action icon="help" disabled={saving} onPress={toggleHelper} />
     <Appbar.Action
      icon="content-save"
      disabled={saving}
      onPress={async () => {
       setSaving(true);
       updateInfo(localInfo)
        .catch((error) => {
         window.toast("Erro inesperado (x581): " + error.message);
         throw error;
        })
        .finally(() => {
         setSaving(false);
         setEditing(false);
        });
      }}
     />
     <Appbar.Action
      icon="close"
      onPress={async () => {
       setEditing(false);
      }}
     />
    </>
   );
  else
   return (
    <>
     <Appbar.Action icon="help" disabled={saving} onPress={toggleHelper} />
     <Appbar.Action
      icon="pencil"
      onPress={() => {
       setEditing(true);
       setLocalInfo(info);
       setTimeout(() => {
        if (ref.current && ref.current.style) {
         ref.current.style.height = "1px";
         ref.current.style.height = ref.current.scrollHeight + "px";
        }
       }, 100);
      }}
     />
    </>
   );
 }, [editing, info, isAdmin, localInfo, updateInfo, saving, toggleHelper]);

 return (
  <>
   <Portal>
    <Dialog
     style={{ width: "90%", maxWidth: 500, alignSelf: "center", maxHeight: "70%" }}
     visible={helperOpen}
     onDismiss={toggleHelper}>
     <Dialog.Title>Ajuda</Dialog.Title>
     <Dialog.ScrollArea>
      <ScrollView>
       <Text variant="labelLarge">
        Você pode usar essa página para inserir avisos, anotações, informações ou qualquer outra
        coisa que desejar para serem visualizadas por todos os usuários do aplicativo.
       </Text>
       <Text style={{ marginTop: 20, marginBottom: 5 }} variant="bodySmall">
        Você pode utilizar os seguintes padrões de código para alterar a formatação do seu texto:
       </Text>

       <Card mode="contained" style={{ marginBottom: 10, marginTop: 10 }}>
        <Card.Content>
         <Text style={{ fontWeight: "bold", fontSize: 22 }}>Título</Text>
         <Text variant="bodySmall">Para criar um título, utilize o seguinte padrão:</Text>
         <Text variant="titleSmall">|seu texto aqui|</Text>
        </Card.Content>
       </Card>
       <Card mode="contained" style={{ marginBottom: 10 }}>
        <Card.Content>
         <Text style={{ fontWeight: "bold" }}>Negrito</Text>
         <Text variant="bodySmall">Para deixar um texto negrito, utilize o seguinte padrão:</Text>
         <Text variant="titleSmall">*seu texto aqui*</Text>
        </Card.Content>
       </Card>
       <Card mode="contained" style={{ marginBottom: 10 }}>
        <Card.Content>
         <Text style={{ fontStyle: "italic" }}>Itálico</Text>
         <Text variant="bodySmall">Para deixar um texto itálico, utilize o seguinte padrão:</Text>
         <Text variant="titleSmall">_seu texto aqui_</Text>
        </Card.Content>
       </Card>
       <Card mode="contained" style={{ marginBottom: 10 }}>
        <Card.Content>
         <Text style={{ color: "blue", textDecorationLine: "underline" }}>Clique Aqui (Link)</Text>
         <Text variant="bodySmall">
          Para criar um link para levar o usuário a algum website, utilize o seguinte padrão:
         </Text>
         <Text variant="titleSmall">^url^</Text>
         <Text variant="labelSmall" style={{ marginTop: 5 }}>
          Ex: ^https://www.youtube.com/^ irá criar um link com a legenda "Clique aqui", que ao ser
          clicado, levará o usuário ao site do Youtube
         </Text>
        </Card.Content>
       </Card>
       <Text variant="labelSmall">
        OBS: Você pode fazer combinações para aplicar múltiplas formatações ao mesmo tempo (Ex:
        _*meu texto*_ irá deixar o texto negrito e itálico ao mesmo tempo)
       </Text>
       <Text style={{ marginTop: 10 }} variant="labelSmall">
        OBS 2: Caso você possua conhecimentos em HTML/CSS, você pode também formatar essa página
        utilizando tags para um controle completo do visual dela.
       </Text>
      </ScrollView>
     </Dialog.ScrollArea>
     <Dialog.Actions>
      <Button onPress={toggleHelper}>Fechar</Button>
     </Dialog.Actions>
    </Dialog>
   </Portal>
   <Appbar.Header>
    <Appbar.Content title="Anotações" />
    {topActions}
   </Appbar.Header>
   {editing ? (
    <ScrollView style={{ padding: 20, backgroundColor: "white" }}>
     <TextInput
      style={{
       minHeight: 40,
       padding: 10,
       borderRadius: 5,
       marginBottom: 30,
       backgroundColor: theme.colors.surfaceVariant,
       color: theme.colors.onSurfaceVariant,
      }}
      ref={ref}
      defaultValue={info}
      onChangeText={(val) => setLocalInfo(val)}
      multiline
     />
    </ScrollView>
   ) : Platform.OS === "web" ? (
    <ScrollView style={{ backgroundColor: "white" }}>
     <div
      style={{ fontFamily: "Verdana,sans-serif", padding: "20px" }}
      dangerouslySetInnerHTML={{ __html: parseText(info) }}
     />
    </ScrollView>
   ) : (
    <WebView
     style={{ height: "100%" }}
     ref={webviewRef}
     source={{
      html: `<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/><div style="padding: 20px">${parseText(
       info
      )}</div>`,
     }}
     onShouldStartLoadWithRequest={(event) => {
      if (event.url !== "about:blank") {
       webviewRef.current.stopLoading();
       Linking.openURL(event.url);
       return false;
      }
      return true;
     }}
     onNavigationStateChange={(event) => {
      if (event.url !== "about:blank") {
       webviewRef.current.stopLoading();
       Linking.openURL(event.url);
      }
     }}
    />
   )}
  </>
 );
}

function parseText(text) {
 let failSafe = 0;
 let result = null;

 text = text.replace(/\n/g, "<br />");

 // negrito
 do {
  failSafe += 1;
  result = /(\*)(.*?)(\*)/g.exec(text);
  if (result) {
   text = text.replace(result[0], `<b>${result[2]}</b>`);
  }
 } while (result && failSafe < 1000);

 failSafe = 0;
 result = null;

 // itálico
 do {
  failSafe += 1;
  result = /(_)(.*?)(_)/g.exec(text);
  if (result) {
   text = text.replace(result[0], `<i>${result[2]}</i>`);
  }
 } while (result && failSafe < 1000);

 failSafe = 0;
 result = null;

 // header
 do {
  failSafe += 1;
  result = /(\|)(.*?)(\|)/g.exec(text);

  if (result) {
   text = text.replace(result[0], `<b style="font-size: 20px">${result[2]}</b>`);
  }
 } while (result && failSafe < 1000);

 failSafe = 0;
 result = null;

 // links
 do {
  failSafe += 1;
  result = /(\^)(.*?)(\^)/g.exec(text);

  if (result) {
   text = text.replace(result[0], `<a href="${result[2]}" target="_blank">Clique aqui</a>`);
  }
 } while (result && failSafe < 1000);

 return text;
}
