import React, { useCallback, useEffect, useMemo, useState } from "react";
import useDatabase from "../databases/FirebaseDatabase";
import { FlatList, Platform, ScrollView, View } from "react-native";
import {
 Text,
 SegmentedButtons,
 Appbar,
 useTheme,
 Portal,
 Dialog,
 Button,
 TextInput,
 HelperText,
} from "react-native-paper";

export default function UsersView({ navigation }) {
 const theme = useTheme();
 const { users, getLocalStorage, user, systemConfig } = useDatabase();

 const [newUser, setNewUser] = useState(false);
 const [errorMessage, setErrorMessage] = useState("");
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [confirmPassword, setConfirmPassword] = useState("");
 const [loading, setLoading] = useState(false);

 const parsedData = useMemo(() => {
  return Object.keys(users).map((key) => {
   return { ...users[key], id: key };
  });
 }, [users]);

 const onDismiss = useCallback(() => {
  setNewUser(false);
  setEmail("");
  setPassword("");
  setConfirmPassword("");
 }, []);

 useEffect(() => {
  setErrorMessage("");
 }, [email, password, confirmPassword]);

 return (
  <>
   <Appbar.Header>
    <Appbar.Content title="Gerenciar Usuários" />
    {systemConfig.allowUserManagement ? (
     <Appbar.Action
      icon="plus"
      onPress={() => {
       setNewUser(true);
      }}
     />
    ) : null}
   </Appbar.Header>
   <Portal>
    <Dialog
     style={{ width: "80%", maxWidth: 500, marginHorizontal: 30, alignSelf: "center" }}
     visible={newUser}
     onDismiss={onDismiss}>
     <Dialog.Title>Novo Usuário</Dialog.Title>
     <Dialog.Content>
      <Text style={{ marginBottom: 15 }} variant="labelMedium">
       Digite o email e a senha do novo usuário. Após a criação do usuário, o mesmo precisará entrar
       no sistema para que ele apareça na lista.
      </Text>
      <TextInput
       mode="outlined"
       label="Email"
       left={<TextInput.Icon icon="email" />}
       onChangeText={(text) => setEmail(text)}
       defaultValue=""
       autoCapitalize="none"
       autoCorrect={false}
       keyboardType={Platform.OS === "android" ? "visible-password" : "email-address"} // foi o único jeito que eu encontrei de não auto completar no meu celular (Bug?)
       outlineStyle={{ ...(Platform.OS === "web" && { marginTop: "2px" }) }}
      />
      <TextInput
       mode="outlined"
       label="Senha"
       left={<TextInput.Icon icon="key" />}
       onChangeText={(text) => setPassword(text)}
       defaultValue=""
       autoCapitalize="none"
       autoCorrect={false}
       outlineStyle={{ ...(Platform.OS === "web" && { marginTop: "2px" }) }}
      />
      <TextInput
       mode="outlined"
       label="Confirmar Senha"
       left={<TextInput.Icon icon="key" />}
       onChangeText={(text) => setConfirmPassword(text)}
       defaultValue=""
       autoCapitalize="none"
       autoCorrect={false}
       outlineStyle={{ ...(Platform.OS === "web" && { marginTop: "2px" }) }}
      />
      <HelperText type="error" visible={!!errorMessage}>
       {errorMessage}
      </HelperText>
     </Dialog.Content>
     <Dialog.Actions>
      <Button
       disabled={loading}
       onPress={() => {
        onDismiss();
       }}>
       Cancelar
      </Button>
      <Button
       disabled={loading}
       onPress={async () => {
        setErrorMessage("");

        if (password !== confirmPassword) {
         setErrorMessage("As duas senhas não coincidem.");
         return;
        }

        if (password.length < 6) {
         setErrorMessage("A senha deve ter no mínimo 6 caracteres.");
         return;
        }

        // check if email is valid (regex gerado pelo github copilot)
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
         setErrorMessage("Email inválido.");
         return;
        }

        setLoading(true);

        const client = await getLocalStorage("clientKey");
        const token = await user.getIdToken();

        await fetch(
         `https://managfy.netlify.app/.netlify/functions/create-user?name=${client}&token=${token}`,
         {
          method: "POST",
          headers: {
           "Content-Type": "application/json",
          },
          body: JSON.stringify({
           email,
           password,
          }),
         }
        )
         .then(async (res) => {
          if (res.status !== 200) {
           const error = await res.text();
           setErrorMessage(error);
           return;
          }

          window.toast("Usuário criado com sucesso!");
          onDismiss();
         })
         .catch((e) => {
          setErrorMessage(e.message);
         });

        setLoading(false);
       }}>
       Criar
      </Button>
     </Dialog.Actions>
    </Dialog>
   </Portal>
   <FlatList
    style={{ backgroundColor: theme.colors.background }}
    data={parsedData}
    renderItem={({ index, item }) => <ListCard key={index} data={item} index={index} />}
   />
  </>
 );
}

function ListCard({ data, index }) {
 const { removeUser, toggleAdmin, user, systemConfig } = useDatabase();
 const [loading, setLoading] = useState(false);
 const theme = useTheme();

 const options = useMemo(() => {
  const opt = [
   {
    value: "delete",
    icon: "trash-can-outline",
    style: { backgroundColor: "#ed5a55" },
    disabled: loading,
    onPress: () => {
     window.confirmationModal({
      title: "Remover Usuário?",
      description: systemConfig.allowUserManagement
       ? "Essa ação é irreversível, e o usuário perderá o acesso ao sistema."
       : "Isso não o impedirá de realizar o login novamente. Para isso, exclua-o do banco de dados através do console do firebase.",
      positiveLabel: "Excluir",
      positiveCallback: async () => {
       setLoading(true);
       removeUser(data.id).then(() => setLoading(false));
      },
     });
    },
   },
  ];

  if (data.admin)
   opt.unshift({
    value: "removeAdmin",
    label: "Administrador",
    disabled: loading,
    icon: "account-reactivate",
    onPress: () => {
     setLoading(true);
     toggleAdmin(data.id, !data.admin).then(() => {
      setLoading(false);
     });
    },
   });
  else
   opt.unshift({
    value: "removeAdmin",
    label: "Padrão",
    disabled: loading,
    icon: "account-reactivate",
    onPress: () => {
     setLoading(true);
     toggleAdmin(data.id, !data.admin).then(() => {
      setLoading(false);
     });
    },
   });

  return opt;
 }, [data.admin, data.id, toggleAdmin, loading, removeUser]);

 if (user.uid === data.id) return null;
 return (
  <View
   style={{
    backgroundColor: index % 2 === 0 ? theme.colors.background : theme.colors.secondaryContainer,
    paddingVertical: 20,
    paddingHorizontal: 10,
    maxWidth: 1000,
    marginHorizontal: "auto",
    width: "100%",
   }}>
   <View style={{ display: "flex", flexDirection: "row" }}>
    <Text variant="labelLarge" style={{ marginLeft: 5 }}>
     {data.email}
    </Text>
    {/* <Text style={{ marginLeft: "auto" }} variant="labelSmall">
     {data.admin ? "Administrador" : "Conta Padrão"}
    </Text> */}
   </View>
   <SegmentedButtons
    style={{ marginTop: 20, maxWidth: 500 }}
    value={options}
    density="small"
    buttons={options}
    onValueChange={() => {}}
   />
  </View>
 );
}
