import { useMemo } from "react";
import { Platform } from "react-native";
import { TextInput, HelperText } from "react-native-paper";

export default function StringInput({
 onChange,
 placeholder,
 icon,
 value,
 isRequired = false,
 invalidMessage,
 lines = 1,
}) {
 const isMulti = useMemo(() => lines > 1, [lines]);
 const label = useMemo(() => `${placeholder} ${isRequired ? "*" : ""}`, [isRequired, placeholder]);
 const parsedIcon = useMemo(() => (icon ? <TextInput.Icon icon={icon} /> : null), [icon]);

 return (
  <>
   <TextInput
    // dense
    mode="outlined"
    error={!!invalidMessage}
    label={label}
    left={parsedIcon}
    onChangeText={onChange}
    defaultValue={value}
    numberOfLines={lines}
    multiline={isMulti}
    outlineStyle={{...(Platform.OS === 'web' && {marginTop: '2px'})}}
   />
   <HelperText type="error" visible={!!invalidMessage}>
    {invalidMessage}
   </HelperText>
  </>
 );
}
