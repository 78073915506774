// para criar novos temas: https://callstack.github.io/react-native-paper/docs/guides/theming
// https://m3.material.io/theme-builder#/custom
const baseTheme = {
 lightTheme: {
  colors: {
   primary: "rgb(44, 93, 167)",
   onPrimary: "rgb(255, 255, 255)",
   primaryContainer: "rgb(215, 227, 255)",
   onPrimaryContainer: "rgb(0, 27, 63)",
   secondary: "rgb(86, 94, 113)",
   onSecondary: "rgb(255, 255, 255)",
   secondaryContainer: "rgb(218, 226, 249)",
   onSecondaryContainer: "rgb(19, 28, 43)",
   tertiary: "rgb(112, 85, 116)",
   onTertiary: "rgb(255, 255, 255)",
   tertiaryContainer: "rgb(250, 216, 253)",
   onTertiaryContainer: "rgb(41, 19, 46)",
   error: "rgb(186, 26, 26)",
   onError: "rgb(255, 255, 255)",
   errorContainer: "rgb(255, 218, 214)",
   onErrorContainer: "rgb(65, 0, 2)",
   background: "rgb(253, 251, 255)",
   onBackground: "rgb(26, 27, 31)",
   surface: "rgb(253, 251, 255)",
   onSurface: "rgb(26, 27, 31)",
   surfaceVariant: "rgb(224, 226, 236)",
   onSurfaceVariant: "rgb(68, 71, 78)",
   outline: "rgb(116, 119, 127)",
   outlineVariant: "rgb(196, 198, 208)",
   shadow: "rgb(0, 0, 0)",
   scrim: "rgb(0, 0, 0)",
   inverseSurface: "rgb(47, 48, 51)",
   inverseOnSurface: "rgb(242, 240, 244)",
   inversePrimary: "rgb(171, 199, 255)",
   elevation: {
    level0: "transparent",
    level1: "rgb(243, 243, 251)",
    level2: "rgb(236, 238, 248)",
    level3: "rgb(230, 234, 245)",
    level4: "rgb(228, 232, 244)",
    level5: "rgb(224, 229, 243)",
   },
   surfaceDisabled: "rgba(26, 27, 31, 0.12)",
   onSurfaceDisabled: "rgba(26, 27, 31, 0.38)",
   backdrop: "rgba(45, 48, 56, 0.4)",
  },
 },
 darkTheme: {
  colors: {
   primary: "rgb(171, 199, 255)",
   onPrimary: "rgb(0, 47, 101)",
   primaryContainer: "rgb(3, 69, 142)",
   onPrimaryContainer: "rgb(215, 227, 255)",
   secondary: "rgb(190, 198, 220)",
   onSecondary: "rgb(40, 48, 65)",
   secondaryContainer: "rgb(62, 71, 89)",
   onSecondaryContainer: "rgb(218, 226, 249)",
   tertiary: "rgb(221, 188, 224)",
   onTertiary: "rgb(63, 40, 68)",
   tertiaryContainer: "rgb(87, 62, 92)",
   onTertiaryContainer: "rgb(250, 216, 253)",
   error: "rgb(255, 180, 171)",
   onError: "rgb(105, 0, 5)",
   errorContainer: "rgb(147, 0, 10)",
   onErrorContainer: "rgb(255, 180, 171)",
   background: "rgb(26, 27, 31)",
   onBackground: "rgb(227, 226, 230)",
   surface: "rgb(26, 27, 31)",
   onSurface: "rgb(227, 226, 230)",
   surfaceVariant: "rgb(68, 71, 78)",
   onSurfaceVariant: "rgb(196, 198, 208)",
   outline: "rgb(142, 144, 153)",
   outlineVariant: "rgb(68, 71, 78)",
   shadow: "rgb(0, 0, 0)",
   scrim: "rgb(0, 0, 0)",
   inverseSurface: "rgb(227, 226, 230)",
   inverseOnSurface: "rgb(47, 48, 51)",
   inversePrimary: "rgb(44, 93, 167)",
   elevation: {
    level0: "transparent",
    level1: "rgb(33, 36, 42)",
    level2: "rgb(38, 41, 49)",
    level3: "rgb(42, 46, 56)",
    level4: "rgb(43, 48, 58)",
    level5: "rgb(46, 51, 62)",
   },
   surfaceDisabled: "rgba(227, 226, 230, 0.12)",
   onSurfaceDisabled: "rgba(227, 226, 230, 0.38)",
   backdrop: "rgba(45, 48, 56, 0.4)",
  },
 },
};

export default baseTheme;
