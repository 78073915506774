import React, { useEffect } from "react";
import useDatabase from "../databases/FirebaseDatabase";
import { Appbar, Text, useTheme } from "react-native-paper";
import { FlatList, Pressable, View } from "react-native";
import { useRoute } from "@react-navigation/native";
import useFab from "../components/UserFab";

export default function CustomListView({ navigation }) {
 const theme = useTheme();
 const { systemConfig } = useDatabase();

 // código para mostrar fab na rota
 const { showFab } = useFab();
 useEffect(() => {
  const unsubscribe = navigation.addListener("focus", () => {
   showFab();
  });
  return unsubscribe;
 }, [navigation, showFab]);

 return (
  <View style={{ height: "100%" }}>
   <Appbar.Header>
    <Appbar.Content title="Listas Customizadas" />
   </Appbar.Header>
   <FlatList
    style={{ height: "100%", backgroundColor: theme.colors.background }}
    data={systemConfig.customLists}
    renderItem={({ index, item }) => (
     <Pressable
      onPress={() => {
       navigation.navigate("Editar Lista", {
        index,
       });
      }}>
      <ListCard key={index} data={item} index={index} />
     </Pressable>
    )}
   />
  </View>
 );
}

function ListCard({ data, index }) {
 const theme = useTheme();
 const { customLists } = useDatabase();

 return (
  <View
   style={{
    display: "flex",
    flexDirection: "row",
    backgroundColor: index % 2 === 0 ? theme.colors.background : theme.colors.secondaryContainer,
    padding: 20,
    maxWidth: 1000,
    marginHorizontal: "auto",
    width: "100%",
   }}>
   <View>
    <Text variant="titleLarge">{data.label}</Text>
    <Text variant="titleSmall">
     {data.type === "single" ? "Lista Simples" : "Lista Quantitativa"}
    </Text>
   </View>
   <Text variant="titleSmall" style={{ marginLeft: "auto" }}>
    {customLists[data.alias]?.length || 0} Itens
   </Text>
  </View>
 );
}
