import DataListInput from "../components/DataListInput";
import DateInput from "../components/DateInput";
import ImageListInput from "../components/ImageListInput";
import AmountCustomListInput from "../components/AmountCustomListInput";
import SelectInput from "../components/SelectInput";
import SingleCustomListInput from "../components/SingleCustomListInput";
import TextInput from "../components/TextInput";
import UserInput from "../components/UserInput";
import { Text } from "react-native-paper";
import FileInput from "../components/FileInput";
import MultiTextInput from "../components/MultiTextInput";
import CurrencyInput from "../components/CurrencyInput";
import PhoneInput from "../components/PhoneInput";
import DateTimeInput from "../components/DateTimeInput";

export default function decideInput(field, data, updateData, invalidMessage, customLists = {}) {
 if (field.type === "string" || field.type === "address")
  return (
   <TextInput
    key={field.key}
    placeholder={field.label}
    icon={field.icon}
    value={data[field.key]}
    onChange={(val) => {
     const obj = {};
     obj[field.key] = val;
     updateData(obj);
    }}
    isRequired={field.isRequired}
    invalidMessage={invalidMessage}
    lines={field.lines}
   />
  );
 if (field.type === "currency")
  return (
   <CurrencyInput
    key={field.key}
    placeholder={field.label}
    icon={field.icon}
    value={data[field.key]}
    onChange={(val) => {
     const obj = {};
     obj[field.key] = val;
     updateData(obj);
    }}
    isRequired={field.isRequired}
    invalidMessage={invalidMessage}
   />
  );
 if (field.type === "phone")
  return (
   <PhoneInput
    key={field.key}
    placeholder={field.label}
    icon={field.icon}
    value={data[field.key]}
    onChange={(val) => {
     const obj = {};
     obj[field.key] = val;
     updateData(obj);
    }}
    isRequired={field.isRequired}
    invalidMessage={invalidMessage}
   />
  );
 else if (field.type === "date")
  return (
   <DateInput
    key={field.key}
    value={data[field.key]}
    placeholder={field.label}
    icon={field.icon}
    onChange={(val) => {
     const obj = {};
     obj[field.key] = val;
     updateData(obj);
    }}
    isRequired={field.isRequired}
    invalidMessage={invalidMessage}
   />
  );
 else if (field.type === "datetime")
  return (
   <DateTimeInput
    key={field.key}
    value={data[field.key]}
    placeholder={field.label}
    icon={field.icon}
    onChange={(val) => {
     const obj = {};
     obj[field.key] = val;
     updateData(obj);
    }}
    isRequired={field.isRequired}
    invalidMessage={invalidMessage}
   />
  );
 if (field.type === "list")
  return (
   <SelectInput
    key={field.key}
    placeholder={field.label}
    value={data[field.key]}
    options={field.options}
    icon={field.icon}
    onChange={(val) => {
     const obj = {};
     obj[field.key] = val;
     updateData(obj);
    }}
    isRequired={field.isRequired}
    invalidMessage={invalidMessage}
   />
  );
 if (field.type === "user")
  return (
   <UserInput
    key={field.key}
    placeholder={field.label}
    value={data[field.key]}
    icon={field.icon}
    onChange={(val) => {
     const obj = {};
     obj[field.key] = val;
     updateData(obj);
    }}
    isRequired={field.isRequired}
    invalidMessage={invalidMessage}
   />
  );
 if (field.type === "imageList")
  return (
   <ImageListInput
    key={field.key}
    placeholder={field.label}
    value={data[field.key]}
    onChange={(val) => {
     const obj = {};
     obj[field.key] = val;
     updateData(obj);
    }}
   />
  );
 else if (field.type === "dataList") {
  return (
   <DataListInput
    key={field.key}
    value={data[field.key]}
    field={field}
    onChange={(val) => {
     const obj = {};
     obj[field.key] = val;
     updateData(obj);
    }}
   />
  );
 } else if (field.type === "file")
  return (
   <FileInput
    key={field.key}
    placeholder={field.label}
    icon={field.icon}
    value={data[field.key]}
    onChange={(val) => {
     const obj = {};
     obj[field.key] = val;
     updateData(obj);
    }}
    isRequired={field.isRequired}
    invalidMessage={invalidMessage}
   />
  );
 else if (field.type === "multitext")
  return (
   <MultiTextInput
    key={field.key}
    placeholder={field.label}
    icon={field.icon}
    value={data[field.key]}
    onChange={(val) => {
     const obj = {};
     obj[field.key] = val;
     updateData(obj);
    }}
    isRequired={field.isRequired}
    invalidMessage={invalidMessage}
   />
  );
 else if (customLists[field.type]) {
  if (customLists[field.type].type === "amount")
   return (
    <AmountCustomListInput
     key={field.key}
     placeholder={field.label}
     value={data[field.key]}
     options={customLists[field.type].options}
     onChange={(val) => {
      const obj = {};
      obj[field.key] = val;
      updateData(obj);
     }}
     isRequired={field.isRequired}
     invalidMessage={invalidMessage}
     icon={field.icon}
    />
   );
  else
   return (
    <SingleCustomListInput
     key={field.key}
     placeholder={field.label}
     value={data[field.key]}
     options={customLists[field.type].options}
     onChange={(val) => {
      const obj = {};
      obj[field.key] = val;
      updateData(obj);
     }}
     isRequired={field.isRequired}
     invalidMessage={invalidMessage}
     icon={field.icon}
    />
   );
 } else return <Text key={field.key}>Campo Inexistente</Text>;
}
