import { createElement, useCallback, useMemo, useState } from "react";
import { Platform, Pressable } from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import { TextInput, HelperText, useTheme, Text } from "react-native-paper";
import { useApp } from "../App";

export default function DateInput({
 onChange,
 placeholder,
 icon,
 value,
 isRequired = false,
 invalidMessage,
}) {
 const theme = useTheme();
 const { isDark } = useApp();
 const [open, setOpen] = useState(false);
 const [localValue, setLocalValue] = useState(new Date(value));

 const onDateChange = useCallback(
  (_, date) => {
   setOpen(false);
   const iso = date.toISOString();
   if (onChange && date && iso !== value) {
    onChange(iso);
    setLocalValue(date);
   }
  },
  [onChange, value]
 );

 const memoizedValue = useMemo(
  () => (localValue ? localValue.toLocaleDateString() : "Sem Data"),
  [localValue]
 );

 const label = useMemo(() => `${placeholder} ${isRequired ? "*" : ""}`, [isRequired, placeholder]);
 const parsedIcon = useMemo(
  () => (icon ? <TextInput.Icon icon={icon} onPress={() => setOpen(true)} /> : null),
  [icon]
 );

 if (Platform.OS === "web") {
  return (
   <>
    <Text variant="labelMedium">
     {placeholder} {isRequired ? "*" : ""}
    </Text>
    {createElement("input", {
     type: "date",
     value: localValue
      ? `${localValue.getFullYear()}-${("0" + (localValue.getMonth() + 1)).slice(-2)}-${(
         "0" + localValue.getDate()
        ).slice(-2)}`
      : "",
     onInput: (e) => {
      if (!e.target.value) {
       onChange("");
       setLocalValue(null);
       return;
      }
      const d = new Date(e.target.value);

      // ajustando para exatamente meia noite do fuso horário do navegador
      if (d.getTimezoneOffset() > 0) d.setHours(24, 0, 0, 0);
      else d.setHours(0, 0, 0, 0);

      onChange(d.toISOString());
      setLocalValue(d);
     },
     style: {
      padding: "10px",
      fontSize: "16px",
      borderRadius: "4px",
      border: "1px solid rgb(121, 116, 126)",
      backgroundColor: theme.colors.background,
      color: theme.colors.onBackground,
      colorScheme: isDark ? "dark" : "light",
     },
    })}
    <HelperText type="error" visible={!!invalidMessage} style={{ marginBottom: 5 }}>
     {invalidMessage}
    </HelperText>
   </>
  );
 }

 return (
  <>
   <Pressable onPress={() => setOpen(true)}>
    <TextInput
     // dense
     mode="outlined"
     type={Platform.OS === "web" ? "datetime" : "text"}
     error={!!invalidMessage}
     label={label}
     left={parsedIcon}
     onChangeText={onChange}
     value={memoizedValue}
     editable={false}
    />
    <HelperText type="error" visible={!!invalidMessage}>
     {invalidMessage}
    </HelperText>
   </Pressable>
   {open && (
    <DateTimePicker
     dateFormat="shortdate"
     value={localValue}
     mode="date"
     is24Hour
     onChange={onDateChange}
    />
   )}
  </>
 );
}
