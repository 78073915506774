import { useCallback, useEffect, useState } from "react";
import { Button, Dialog, Portal, Text } from "react-native-paper";

export default function ConfirmationHandler() {
 const [visible, setVisible] = useState(false);
 const [modalData, setModalData] = useState({});

 const onDismiss = useCallback(() => setVisible(false), []);

 /*
    {
        title,
        description,
        positiveLabel,
        negativeLabel,
        positiveCallback,
        negativeCallback,
    }
 */
 useEffect(() => {
  window.confirmationModal = (data = {}) => {
   setVisible(true);
   setModalData(data);
  };
 }, []);

 return (
  <Portal>
   <Dialog
    style={{ width: "80%", maxWidth: 500, alignSelf: "center" }}
    visible={visible}
    onDismiss={onDismiss}>
    <Dialog.Title>{modalData.title || "Alerta"}</Dialog.Title>
    <Dialog.Content>
     <Text>{modalData.description || "Tem certeza que deseja fazer isso?"}</Text>
    </Dialog.Content>
    <Dialog.Actions>
     <Button
      onPress={() => {
       if (modalData.negativeCallback) modalData.negativeCallback();
       onDismiss();
      }}>
      {modalData.negativeLabel || "Cancelar"}
     </Button>
     <Button
      onPress={() => {
       if (modalData.positiveCallback) modalData.positiveCallback();
       onDismiss();
      }}>
      {modalData.positiveLabel || "Confirmar"}
     </Button>
    </Dialog.Actions>
   </Dialog>
  </Portal>
 );
}
