import { format } from "date-fns";

const html = `
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </head>
  <body>
    <h2>Ordem de serviço: Requinte Decorações</h2>
    <h3>Cliente:</h3>
    <span>{cliente}</span>
    <h3>Endereço:</h3>
    <span>{endereco}</span>
    <h3>Medidas</h3>
    [medidas__
    <div>
      <h4>Cômodo</h4>
      <span>{item.nome}</span>
      <h4>Medidas</h4>
      <span>{item.medidas}</span>
    </div>
    ]
  </body>
</html>
`;

/**
 * Gera um html para pdf com base no template fornecido pelas configurações, populando
 * o template com as informações de uma ordem
 * @param {object} systemConfig as configurações do sistema
 * @param {object} data os dados de uma ordem de serviço
 */
export default async function generatePdf(systemConfig, data) {
 try {
  let parsed = systemConfig.pdfTemplate;
  let found;

  // aqui precuramos por tokens do tipo soma, que possuem o padrao
  // +{variavel1,variavel2}+
  while ((found = /\+{([\s\S]*?)}\++/g.exec(parsed))) {
   const token = found[0];

   let finalValue = 0;

   // extraimos as variaveis que seráo somadas
   const variables = token.substring(2, token.length - 2).split(",");

   variables.forEach((variable) => {
    // se a variável possuir um ponto, significa que é um array e quere somar
    // todos os valores de uma propriedade (posiçao 1) de cada item do array
    if (variable.includes(".")) {
     const [variable1, variable2] = variable.split(".");
     if (!Array.isArray(data[variable1])) return;

     data[variable1].forEach((item) => {
      if (!item[variable2]) return;
      const n = parseFloat(item[variable2]);
      if (isNaN(n)) return;
      finalValue += n;
     });
    } else {
     if (!data[variable]) return;
     const n = parseFloat(data[variable]);
     if (isNaN(n)) return;
     finalValue += n;
    }
   });

   // extrair a variavel e o o html que será adicionado para cada item no array

   parsed = parsed.replace(token, finalValue.toFixed(2));
  }

  // aqui precuramos por tokens do tipo array, que possuem o padrao
  // [variavel__html]
  while ((found = /\[([\s\S]*?)__([\s\S]*?)\]+/g.exec(parsed))) {
   const token = found[0];

   // extrair a variavel e o o html que será adicionado para cada item no array
   const [variable, itemHtml] = token.replace("[", "").replace("]", "").split("__");

   let fullHtml = "";
   if (Array.isArray(data[variable])) {
    // para cada item no array, iremos adicionar o itemHtml, porém substituiremos os tokens
    // {item.variable} para {variavel.index.variavel2}, para que o regex posterior possa substituir pelos
    // valores verdadeiros
    data[variable].forEach((_, index) => {
     const parsedItemHtml = itemHtml.replace(/{item/g, `{${variable}.${index}`);
     fullHtml += parsedItemHtml;
    });
   }

   parsed = parsed.replace(token, fullHtml);
  }

  // pegaremos agora os tokens {variavel} e {variavel.index.variavel2}
  // para subistiurmos eles pelos valores reais
  // negative lookahead para ignorar os tokens que estão dentro de <style></style>
  while ((found = /{(?![^<]*<\/style>)(.*?)}+/g.exec(parsed))) {
   const token = found[0];

   let [path, alt] = token.replace("{", "").replace("}", "").split("|");
   path = path.split(".");

   let val = data;

   for (let i = 0; i < path.length; i++) {
    if (!val) break;
    val = val[path[i]];
   }

   //  if (childVariable) val = data[variable][index][childVariable];
   //  else if (index >= 0) val = data[variable][index];
   //  else val = data[variable];

   if (val === undefined || val === null || val === "") val = alt || "";

   const isoDateStringRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3})?Z$/;
   if (isoDateStringRegex.test(val)) val = format(new Date(val), "dd/MM/yyyy");

   parsed = parsed.replace(token, val);
  }

  return parsed;
 } catch (e) {
  window.toast("Houve um problema ao tentarmos gerarmos o seu PDF: " + e.message);
 }
}
