import { useMemo, useState } from "react";
import { Platform, Pressable } from "react-native";
import {
 Text,
 Searchbar,
 useTheme,
 HelperText,
 Menu,
 TextInput,
 Button,
 FAB,
 Appbar,
} from "react-native-paper";

export default function SingleCustomListInput({
 onChange,
 value,
 options,
 placeholder,
 isRequired = false,
 invalidMessage,
 icon,
}) {
 const [open, setOpen] = useState(false);
 const [localValue, setLocalValue] = useState(options.find((i) => i === value));

 const memoizedOptions = useMemo(
  () =>
   options.map((option, index) => (
    <Menu.Item
     key={index}
     title={option}
     onPress={() => {
      onChange(option);
      setLocalValue(option);
      setOpen(false);
     }}
    />
   )),
  [options, onChange]
 );

 return (
  <>
   <Menu
    visible={open}
    onDismiss={() => setOpen(false)}
    anchor={
     <Pressable onPress={() => setOpen(true)}>
      <TextInput
       outlineStyle={{ ...(Platform.OS === "web" && { marginTop: "2px" }) }}
       mode="outlined"
       error={!!invalidMessage}
       label={`${placeholder} ${isRequired ? "*" : ""}`}
       left={icon ? <TextInput.Icon icon={icon} onPress={() => setOpen(true)} /> : null}
       value={localValue}
       right={<TextInput.Icon icon="chevron-down" onPress={() => setOpen(true)} />}
       editable={false}
      />
     </Pressable>
    }>
    {memoizedOptions}
   </Menu>
   <HelperText type="error" visible={!!invalidMessage}>
    {invalidMessage}
   </HelperText>
  </>
 );
}
