import { useCallback, useState } from "react";
import { Platform, Pressable, View } from "react-native";
import { Menu, TextInput } from "react-native-paper";

export default function CustomListMenu({ options, index, updateItem, data }) {
 const [open, setOpen] = useState(false);
 const [localValue, setLocalValue] = useState(data.name);

 const generateOptions = useCallback(
  (index) =>
   options.map((name, i) => (
    <Menu.Item
     key={i}
     title={name}
     onPress={() => {
      updateItem({ name }, index);
      setLocalValue(name);
      setOpen(false);
     }}
    />
   )),
  [options, updateItem]
 );

 return (
  <View style={{ width: "100%", flex: 1 }}>
   <Menu
    visible={open}
    onDismiss={() => setOpen(false)}
    anchor={
     <Pressable onPress={() => setOpen(true)}>
      <TextInput
       dense
       outlineStyle={{ ...(Platform.OS === "web" && { marginTop: "2px" }) }}
       mode="outlined"
       label={`Item ${index}`}
       value={localValue}
       editable={false}
       style={{ width: "100%" }}
       right={<TextInput.Icon icon="chevron-down" onPress={() => setOpen(true)} />}
      />
     </Pressable>
    }>
    {generateOptions(index)}
   </Menu>
  </View>
 );
}
