import { useEffect } from "react";
import useDatabase from "../databases/FirebaseDatabase";
import { Linking, Platform } from "react-native";

export default function VersionController() {
 const { getLocalStorage, saveLocalStorage } = useDatabase();

 useEffect(() => {
  if (Platform.OS === "web") return; // versao somente em android

  fetch(`https://managfy.netlify.app/.netlify/functions/app-version`)
   .catch((e) => {
    console.warn(e.message);
   })
   .then(async (res) => {
    if (res.status !== 200) {
     const error = await res.text();
     console.warn(error);
    } else {
     const data = await res.json();
     if (data.version) {
      data.version = data.version.toString();

      const currentVersion = await getLocalStorage("appVersion");

      // se nao tiver versão, provavelmenteo usuario acabou de instalar o app,
      // ou resetou o cache, então nao mostraremos aviso
      if (!currentVersion) {
       saveLocalStorage("appVersion", data.version);
       return;
      }

      if (data.version !== currentVersion) {
       window.confirmationModal({
        title: "Nova Versão!",
        description:
         "Uma nova versão do aplicativo se encontra disponível para download. Gostaria de atualizar agora? (RECOMENDADO)",
        positiveLabel: "Sim",
        negativeLabel: "Mais Tarde",
        positiveCallback: async () => {
         await saveLocalStorage("appVersion", data.version);
         Linking.openURL("https://play.google.com/store/apps/details?id=com.mbello.Managfy");
        },
       });
      }
     }
    }
   });
 }, [getLocalStorage, saveLocalStorage]);

 return null;
}
