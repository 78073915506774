import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import getDefaultData from "../services/getDefaultData";
import decideInput from "../services/decideInput";
import { useEdit } from "../views/EditView";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import useDatabase from "../databases/FirebaseDatabase";
import { useTheme, Card, Button, IconButton, Text } from "react-native-paper";
import { cachedDataLists } from "../services/decideField";
import { nanoid } from "nanoid";
import { View } from "react-native";

export default function DataListInput({ field: f, value, onChange }) {
 const theme = useTheme();
 const { mappedCustomLists } = useDatabase();

 // criamos localData pois como estamos trabalhando com refs
 // a lista que vem de cima nem sempre estará atualizada corretamente
 const [localValue, setLocalValue] = useState(null);
 const [currentIndex, setCurrentIndex] = useState(0);

 const mappedFields = useMemo(() => {
  if (cachedDataLists[f.key]) return cachedDataLists[f.key];
  const fields = [];
  Object.keys(f.fields).forEach((key) => {
   const field = f.fields[key];
   field.key = key; // jogamos o o identificador do field pra dentro do objeto
   fields.push(field);
  });
  cachedDataLists[f.key] = fields;
  return fields;
 }, [f]);

 const updateItem = useCallback(
  (index, val) => {
   const newData = [...localValue];
   newData[index] = { ...newData[index], ...val };
   setLocalValue(newData);
   onChange(newData);
  },
  [onChange, localValue]
 );

 const removeItem = useCallback(
  (index) => {
   const newData = [...localValue];
   newData.splice(index, 1);
   setLocalValue(newData);
   onChange(newData);
   setCurrentIndex((i) => {
    if (newData[i] || i === 0) return i;
    else return i - 1;
   });
  },
  [onChange, localValue]
 );

 const addItem = useCallback(() => {
  const newData = [...localValue];
  const defaultData = getDefaultData(f.fields, f.key);
  newData.push(defaultData);
  setLocalValue(newData);
  onChange(newData);
  setCurrentIndex((i) => {
   if (newData.length > 1) return i + 1;
   else return 0;
  });
 }, [f, onChange, localValue]);

 useEffect(() => {
  if (localValue) return;
  const defaultData = getDefaultData(f.fields, f.key);
  const newData = [];
  value.forEach((item) => {
   newData.push({
    ...defaultData,
    ...item,
   });
  });
  setLocalValue(newData);
  onChange(newData);
 }, [f, value, onChange, localValue]);

 if (!localValue) return null;
 return (
  <>
   <View
    style={{
     display: "flex",
     flexDirection: "row",
     alignItems: "center",
     justifyContent: "center",
     gap: 5,
    }}>
    {localValue.length > 0 ? (
     <>
      <IconButton
       icon="arrow-left"
       disabled={localValue[currentIndex - 1] === undefined}
       onPress={() => {
        setCurrentIndex((i) => i - 1);
       }}
       title="Left"
      />
      <Text variant="labelSmall" style={{ textAlign: "center" }}>
       {currentIndex + 1}/{localValue.length}
      </Text>
      {localValue[currentIndex + 1] !== undefined ? (
       <IconButton
        icon="arrow-right"
        onPress={() => {
         setCurrentIndex((i) => i + 1);
        }}
        title="Right"
       />
      ) : (
       <IconButton icon="plus-circle" onPress={addItem} title="Add" />
      )}
      <IconButton
       style={{ marginLeft: "auto" }}
       mode="contained"
       icon="delete-outline"
       iconColor={theme.colors.error}
       onPress={() => removeItem(currentIndex)}>
       Remover
      </IconButton>
     </>
    ) : (
        
     <IconButton icon="plus-circle" size={30} onPress={addItem} title="Add" />
    )}
   </View>
   {localValue[currentIndex] !== undefined && (
    <DataListItem
     key={`${currentIndex}_${localValue.length}`}
     data={localValue[currentIndex]}
     mappedCustomLists={mappedCustomLists}
     mappedFields={mappedFields}
     onChange={(data) => {
      updateItem(currentIndex, data);
     }}
     index={currentIndex}
    />
   )}
  </>
 );
}

function DataListItem({ mappedFields, data, onChange, mappedCustomLists, onRemove, index }) {
 const { invalidFields } = useEdit();

 return (
  <Card mode="elevated" style={{ marginBottom: 20 }}>
   <Card.Content>
    {mappedFields.map((field) =>
     decideInput(
      field,
      data,
      (val) => {
       onChange(val);
      },
      invalidFields[`${field.key}-${index}`],
      mappedCustomLists
     )
    )}
   </Card.Content>
  </Card>
 );
}
