import { createDownloadResumable, documentDirectory } from "expo-file-system";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import useDatabase from "../databases/FirebaseDatabase";

const FileDonwloaderContext = createContext({});

export function FileDownloader({ children }) {
 const [progess, setProgress] = useState(0);

 const progressCallback = useCallback((downloadProgress) => {
  const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
  setProgress(progress);
 }, []);

 const downloadFile = useCallback(
  async (fileUrl, filename) => {
   try {
    const dl = createDownloadResumable(fileUrl, documentDirectory + filename, {}, progressCallback);
    const file = await dl.downloadAsync();
    return file.uri;
   } catch {
    window.toast("Hoube um problema ao baixar o arquivo...");
   }
  },
  [progressCallback]
 );

 return (
  <FileDonwloaderContext.Provider value={{ downloadFile }}>
   {children}
  </FileDonwloaderContext.Provider>
 );
}

export default function useFileDownloader() {
 return useContext(FileDonwloaderContext);
}
