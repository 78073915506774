import { isDevice } from "expo-device";
import {
 addNotificationReceivedListener,
 addNotificationResponseReceivedListener,
 AndroidImportance,
 getExpoPushTokenAsync,
 getPermissionsAsync,
 removeNotificationSubscription,
 requestPermissionsAsync,
 setNotificationChannelAsync,
 setNotificationHandler,
} from "expo-notifications";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Alert, Platform } from "react-native";
import useDatabase from "../databases/FirebaseDatabase";

export const NotificationContext = createContext({});

async function sendPushNotification(expoPushToken, body, data) {
 const message = {
  to: expoPushToken,
  sound: "default",
  title: "Managfy",
  body,
  data,
 };

 try {
  await fetch("https://managfy.netlify.app/.netlify/functions/expo-notification", {
   method: "POST",
   headers: {
    "Content-Type": "application/json",
   },
   body: JSON.stringify(message),
  });
 } catch (e) {
  console.error(e);
 }
}

async function registerForPushNotificationsAsync() {
 let token;

 if (Platform.OS === "android") {
  await setNotificationChannelAsync("default", {
   name: "default",
   importance: AndroidImportance.MAX,
   vibrationPattern: [0, 250, 250, 250],
   lightColor: "#FF231F7C",
  });
 }

 if (isDevice) {
  const { status: existingStatus } = await getPermissionsAsync();
  let finalStatus = existingStatus;
  if (existingStatus !== "granted") {
   const { status } = await requestPermissionsAsync();
   finalStatus = status;
  }

  if (finalStatus !== "granted") return;

  token = (await getExpoPushTokenAsync()).data;
  console.log(token);
 }

//  if (window?.toast) window.toast(`Seu token é ${token}`);

 return token;
}

setNotificationHandler({
 handleNotification: async () => ({
  shouldShowAlert: true,
  shouldPlaySound: false,
  shouldSetBadge: false,
 }),
});

export function Notifications({ children }) {
 const { user, updatePushToken, pushTokens } = useDatabase();
 const [expoPushToken, setExpoPushToken] = useState("");
 const [notification, setNotification] = useState(false);
 const notificationListener = useRef();
 const responseListener = useRef();

 useEffect(() => {
  if (Platform.OS === "web") return;

  registerForPushNotificationsAsync().then((token) => setExpoPushToken(token));

  notificationListener.current = addNotificationReceivedListener((notification) => {
   setNotification(notification);
  });

  responseListener.current = addNotificationResponseReceivedListener((response) => {
   console.log(response);
  });

  return () => {
   removeNotificationSubscription(notificationListener.current);
   removeNotificationSubscription(responseListener.current);
  };
 }, []);

 useEffect(() => {
  if (expoPushToken && user && pushTokens[user.uid] !== expoPushToken) {
   updatePushToken(expoPushToken);
  }
 }, [expoPushToken, pushTokens, updatePushToken, user]);

 return (
  <NotificationContext.Provider value={{ sendPushNotification }}>
   {children}
  </NotificationContext.Provider>
 );
}

export default function useNotifications() {
 return useContext(NotificationContext);
}
