import { format } from "date-fns";

export default function parseTokens(text, data) {
 let parsed = text;
 let found;

 // pegaremos agora os tokens {variavel} e {variavel.index.variavel2}
 // para subistiurmos eles pelos valores reais
 // negative lookahead para ignorar os tokens que estão dentro de <style></style>
 while ((found = /{(?![^<]*<\/style>)(.*?)}+/g.exec(parsed))) {
  const token = found[0];

  let [variable, fallback] = token.replace("{", "").replace("}", "").split("|");

  let val = "";

  // converte para date
  if (/\[date\].*/g.exec(variable)) {
   variable = variable.replace("[date]", "");
   if (!data[variable]) return fallback || "";
   const date = new Date(data[variable]);
   val = format(date, "dd/MM/yyyy");
  } else if (/\[datetime\].*/g.exec(variable)) {
   // copnverte para datetime
   variable = variable.replace("[datetime]", "");
   if (!data[variable]) return fallback || "";
   const date = new Date(data[variable]);
   val = format(date, "dd/MM/yyyy HH:mm");
  } else {
   val = data[variable] || fallback || "";
  }

  parsed = parsed.replaceAll(token, val);
 }

 return parsed;
}
