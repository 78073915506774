import React, { useState, useCallback, useMemo } from "react";
import { getDocumentAsync } from "expo-document-picker";
import { SegmentedButtons, TextInput, ActivityIndicator } from "react-native-paper";
import { Platform, View } from "react-native";
import useFileManager from "../hooks/useFileManager";
import { openURL } from "expo-linking";

export default function FileInput({
 onChange,
 value,
 isRequired = false,
 invalidMessage,
 icon,
 placeholder,
}) {
 const { uploadFile, addToDeleteQueue } = useFileManager();
 const [localUploading, setLocalUploading] = useState(false);
 const [localValue, setLocalValue] = useState(value || {});

 const pickFile = useCallback(async () => {
  // No permissions request is necessary for launching the image library
  const result = await getDocumentAsync({});
  if (!result.cancelled) {
   try {
    setLocalUploading(true);
    const uploadResult = await uploadFile(result.assets?.[0]?.uri);
    const obj = { name: result.assets?.[0]?.name, uri: uploadResult.uri, id: uploadResult.id };
    onChange(obj);
    setLocalValue(obj);
   } catch (e) {
    window.toast(`Houve um problema ao realizar o upload: ${e.message}`);
   } finally {
    setLocalUploading(false);
   }
  }
 }, [onChange, uploadFile]);

 const downloadFile = useCallback(async () => {
  if (Platform.OS === "web") window.open(localValue.uri, "_blank");
  else openURL(localValue.uri);
 }, [localValue.uri]);

 const removeFile = useCallback(async () => {
  if (localValue.id) addToDeleteQueue(localValue.id);
  const obj = { id: "", name: "", uri: "" };
  onChange(obj);
  setLocalValue(obj);
 }, [addToDeleteQueue, localValue.id, onChange]);

 const options = useMemo(() => {
  const opt = [
   {
    value: "delete",
    icon: "delete",
    disabled: !localValue.id,
   },
  ];

  if (localValue.id)
   opt.unshift({
    value: "download",
    icon: "open-in-new",
   });
  else
   opt.unshift({
    value: "upload",
    icon: "upload",
   });

  return opt;
 }, [localValue]);

 return (
  <View
   style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
   }}>
   <TextInput
    outlineStyle={{ ...(Platform.OS === "web" && { marginTop: "2px" }) }}
    mode="outlined"
    error={!!invalidMessage}
    label={`${placeholder} ${isRequired ? "*" : ""}`}
    left={icon ? <TextInput.Icon icon={icon} /> : null}
    value={localValue.name || ""}
    editable={false}
    style={{ width: "100%", flex: 1 }}
   />
   {localUploading ? (
    <View style={{ marginLeft: 5 }}>
     <ActivityIndicator animating />
    </View>
   ) : (
    <SegmentedButtons
     style={{ marginTop: 8, marginLeft: 10 }}
     value={value}
     onValueChange={(val) => {
      if (val === "upload") pickFile();
      else if (val === "download") downloadFile();
      else if (val === "delete") removeFile();
     }}
     buttons={options}
    />
   )}
  </View>
 );
}
