import { useEffect, useState } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text, Button } from "react-native-paper";
import { Image, Platform, Pressable, View } from "react-native";
import { shareAsync } from "expo-sharing";
import useFileDownloader from "../hooks/useFileDownloader";

export default function ImageListViewer({ value, placeholder }) {
 const { downloadFile } = useFileDownloader();
 const [currentIndex, setCurrentIndex] = useState(0);

 // se por acaso o value mudar, iremos voltar o index para 0,
 // pois o usuario pode ter removido uma imagem, e estariamos em um index inexistente
 useEffect(() => {
  setCurrentIndex(0);
 }, [value]);

 if (!value) return <Text color="red">Erro #99982</Text>;
 return (
  <View style={{ marginTop: 10 }}>
   <Text variant="labelMedium" style={{ fontWeight: "600" }}>
    {placeholder}
   </Text>
   <View>
    {value.length > 0 && (
     <Text variant="labelSmall" style={{ textAlign: "center" }}>
      {currentIndex + 1}/{value.length}
     </Text>
    )}
    <View
     style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: 5,
     }}>
     {value.length > 0 && (
      <Button
       compact
       disabled={value[currentIndex - 1] === undefined}
       onPress={() => {
        setCurrentIndex((i) => i - 1);
       }}
       contentStyle={{ height: 80 }}
       title="Left">
       <MaterialCommunityIcons name="arrow-left" size={25} />
      </Button>
     )}

     {value[currentIndex] !== undefined && (
      <Pressable
       onLongPress={async () => {
        if (Platform.OS === "web") return;
        window.toast("Preparando imagem para compartilhamento...");
        const uri = await downloadFile(value[currentIndex].uri, `${new Date().getTime()}.png`);
        shareAsync(uri, { UTI: ".png", mimeType: "image/png" });
       }}
       onPress={() => {
        window.showFullScreenImage(value[currentIndex].uri);
       }}>
       <Image
        key={value[currentIndex].uri}
        alt="foto indisponível"
        style={{ width: 200, height: 200, borderRadius: 10, backgroundColor: "white" }}
        source={{ uri: value[currentIndex].uri }}
       />
      </Pressable>
     )}

     <Button
      disabled={value[currentIndex + 1] === undefined}
      contentStyle={{ height: 80 }}
      compact
      onPress={() => {
       setCurrentIndex((i) => i + 1);
      }}
      title="Right">
      <MaterialCommunityIcons name="arrow-right" size={25} />
     </Button>
    </View>
   </View>
  </View>
 );
}
