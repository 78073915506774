import Ajv from "ajv";
import localize from "ajv-i18n/localize/pt-BR";
import { uppercaseFirstLetter } from "./utils";

const ajv = new Ajv({ allErrors: true });

ajv.addKeyword({
 keyword: "isNotEmpty",
 type: "string",
 validate: (_, str) => {
  return str.trim() !== "";
 },
});

export function setupValidator(systemConfig) {
 ajv.addSchema(systemConfig.schema, "item");
}

export async function validate(body) {
 const validate = ajv.getSchema("item");
 if (validate(body)) {
  return { success: true };
 } else {
  localize(validate.errors);
  const invalidFields = {};

  validate.errors.forEach(({ instancePath, message, keyword }) => {
   const arr = instancePath.split("/");

   let fieldId = "";
   if (arr.length === 2) fieldId = arr[1];
   else if (arr.length === 3) fieldId = `${arr[2]}-${arr[1]}`;
   else if (arr.length === 4) fieldId = `${arr[3]}-${arr[2]}`;

   if (keyword === "isNotEmpty") invalidFields[fieldId] = "Campo obrigatório";
   else invalidFields[fieldId] = uppercaseFirstLetter(message);
  });

  return {
   success: false,
   message: "Alguns campos não foram preenchidos corretamente",
   invalidFields,
  };
 }
}
