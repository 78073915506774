import React, { useState, useEffect, useCallback, useMemo } from "react";
import * as Picker from "expo-image-picker";
import { Text, SegmentedButtons } from "react-native-paper";
import { Image as ReactImage, Platform, View } from "react-native";
import useDatabase from "../databases/FirebaseDatabase";

// Função para alterar a qualidade da imagem no web
async function changeWebQuality(uri, quality) {
 if (quality >= 1 || quality <= 0) return uri;

 let dataUrl = uri;

 //eslint-disable-next-line
 const img = new Image();

 img.src = uri;

 await new Promise((resolve) => {
  img.onload = () => {
   const elem = window.document.createElement("canvas");
   elem.width = img.width;
   elem.height = img.height;
   const ctx = elem.getContext("2d");
   ctx.drawImage(img, 0, 0, img.width, img.height);
   dataUrl = ctx.canvas.toDataURL("image/jpeg", quality);
   resolve();
  };
 });

 return dataUrl;
}

export default function ImageInput({
 onChange,
 value,
 onRemove = () => {},
 isFromList = false,
 alwaysShowOnRemove = false,
 isUploading = false,
}) {
 const { systemConfig } = useDatabase();
 const [libStatus, requestLibPermission] = Picker.useMediaLibraryPermissions();
 const [camStatus, requestCamPermission] = Picker.useCameraPermissions();

 useEffect(() => {
  if (!libStatus || !camStatus) return;
  if (camStatus.status !== "granted") requestCamPermission();
  else if (libStatus.status !== "granted") requestLibPermission();
 }, [libStatus, camStatus, requestCamPermission, requestLibPermission]);

 const pickImage = useCallback(async () => {
  // No permissions request is necessary for launching the image library
  const result = await Picker.launchImageLibraryAsync({
   mediaTypes: Picker.MediaTypeOptions.Images,
   //   allowsEditing: true,
   quality: systemConfig.imageCompression || 0.2,
  });

  if (!result.canceled) {
   let uri = result.assets?.[0]?.uri;
   if (Platform.OS === "web" && uri)
    uri = await changeWebQuality(uri, systemConfig.imageCompression || 0.2);
   onChange(uri);
  }
 }, [onChange, systemConfig.imageCompression]);

 const takePhoto = useCallback(async () => {
  // No permissions request is necessary for launching the image library
  const result = await Picker.launchCameraAsync({
   mediaTypes: Picker.MediaTypeOptions.Images,
   quality: systemConfig.imageCompression || 0.2,
  });

  if (!result.canceled) {
   let uri = result.assets?.[0]?.uri;
   if (Platform.OS === "web" && uri)
    uri = await changeWebQuality(uri, systemConfig.imageCompression || 0.2);
   onChange(uri);
  }
 }, [onChange, systemConfig.imageCompression]);

 const options = useMemo(() => {
  const opt = [];

  if (camStatus?.status === "granted")
   opt.push({
    value: "camera",
    icon: "camera",
    disabled: value && isFromList,
   });

  if (libStatus?.status === "granted")
   opt.push({
    value: "gallery",
    icon: "folder-image",
    disabled: value && isFromList,
   });

  if (value || alwaysShowOnRemove)
   opt.push({
    value: "delete",
    icon: "delete",
    disabled: isUploading,
   });

  return opt;
 }, [alwaysShowOnRemove, value, isFromList, camStatus, libStatus, isUploading]);

 if (camStatus?.status !== "granted" && libStatus?.status !== "granted")
  return <Text>Sem Permissões</Text>;
 return (
  <View
   style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
   }}
   alignItems="center"
   my={2}>
   {value ? (
    <ReactImage
     style={{
      width: 200,
      height: 200,
      borderRadius: 10,
      marginBottom: 10,
      backgroundColor: "white",
     }}
     key={value}
     alt="user-photo"
     source={{ uri: value }}
    />
   ) : (
    <Text variant="labelMedium" style={{ margin: 2 }}>
     SELECIONE UMA IMAGEM
    </Text>
   )}
   <SegmentedButtons
    value={value}
    onValueChange={(val) => {
     if (val === "camera") takePhoto();
     else if (val === "gallery") pickImage();
     else if (val === "delete") onRemove();
    }}
    buttons={options}
   />
  </View>
 );
}
