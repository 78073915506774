import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { FAB, Portal } from "react-native-paper";
import { useApp } from "../App";
import useDatabase from "../databases/FirebaseDatabase";

const UserFabContext = createContext({});

export function UserFab({ children }) {
 const { toggleTheme } = useApp();
 const { signOut } = useDatabase();

 const [open, setOpen] = useState(false);
 const [visible, setVisible] = useState(false);
 const [dynamicOptions, setDynamicOptions] = useState([]);

 const onToggle = useCallback(({ open }) => {
  setOpen(open);
 }, []);

 const showFab = useCallback(() => setVisible(true), []);
 const hideFab = useCallback(() => setVisible(false), []);

 const logout = useCallback(() => {
  window.confirmationModal({
   title: "Sair?",
   description: "Você será levado de volta à tela de login.",
   positiveLabel: "Sair",
   positiveCallback: async () => {
    signOut();
   },
  });
 }, [signOut]);

 const addOption = useCallback((id, label, icon, callback = () => {}) => {
  setDynamicOptions((prev) => {
   const newOptions = [...prev];
   const oldIndex = newOptions.findIndex((option) => option.id === id);
   if (oldIndex !== -1) newOptions.splice(oldIndex, 1);
   newOptions.push({ id, label, icon, callback });
   return newOptions;
  });
 }, []);

 const removeOption = useCallback((id) => {
  setDynamicOptions((prev) => {
   const newOptions = [...prev];
   const oldIndex = newOptions.findIndex((option) => option.id === id);
   if (oldIndex !== -1) newOptions.splice(oldIndex, 1);
   return newOptions;
  });
 }, []);

 const options = useMemo(() => {
  if (!open) return [];
  return [
   ...dynamicOptions.map((option) => ({
    icon: option.icon,
    label: option.label,
    onPress: option.callback,
   })),
   {
    icon: "theme-light-dark",
    label: "Alternar Tema",
    onPress: toggleTheme,
   },
   {
    icon: "logout",
    label: "Sair",
    onPress: logout,
   },
  ];
 }, [dynamicOptions, logout, toggleTheme, open]);

 return (
  <UserFabContext.Provider value={{ showFab, hideFab, addOption, removeOption }}>
   <Portal>
    <FAB.Group
     style={{ paddingBottom: 80 }}
     open={open}
     visible={visible}
     icon={open ? "close" : "cog"}
     variant="surface"
     fabStyle={{ borderRadius: 100 }}
     actions={options}
     onStateChange={onToggle}
     onPress={() => {
      if (open) {
       // do something if the speed dial is open
      }
     }}
    />
   </Portal>
   {children}
  </UserFabContext.Provider>
 );
}

export default function useFab() {
 return useContext(UserFabContext);
}
