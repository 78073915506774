import { useCallback, useEffect, useState } from "react";
import { Pressable, View } from "react-native";
import { Appbar, Button, Dialog, Menu, Portal, Text, TextInput } from "react-native-paper";
import useDatabase from "../databases/FirebaseDatabase";

export default function FilterModal({ filterBy, setFilterBy, availableFilters }) {
 const { systemConfig, saveLocalStorage } = useDatabase();
 const [fieldSelector, setFieldSelector] = useState(false);
 const [valueSelector, setValueSelector] = useState(false);
 const [modalOpen, setModalOpen] = useState(false);

 const [fieldIndex, setFieldIndex] = useState(-1);
 const [valueIndex, setValueIndex] = useState(-1);

 const onDismiss = useCallback(() => setModalOpen(false), []);

 // Inciar as variaveis fieldIndex e valueIndex com base no filtro existente
 useEffect(() => {
  if (!modalOpen) return;
  const filteringKey = filterBy.key;
  if (filteringKey !== "_all_") {
   const index = availableFilters.findIndex(({ key }) => key === filteringKey);
   if (index >= 0) {
    setFieldIndex(index);
    const optionIndex = availableFilters[index].options.findIndex(
     ({ value }) => value === filterBy.value
    );
    setValueIndex(optionIndex);
   }
  }
 }, [availableFilters, filterBy, modalOpen]);

 return (
  <>
   <Appbar.Action
    icon={filterBy.value !== "_all_" ? "filter" : "filter-outline"}
    onPress={() => setModalOpen(true)}
   />
   <Portal>
    <Dialog
     style={{ width: "80%", maxWidth: 500, alignSelf: "center" }}
     visible={modalOpen}
     onDismiss={onDismiss}>
     <Dialog.Title>Filtrar Campo</Dialog.Title>
     <Dialog.Content>
      <Text style={{ marginBottom: 15 }} variant="labelMedium">
       Selecione abaixo um campo e um valor, e listaremos apenas os itens que possuírem o campo
       escolhido com o mesmo valor selecionado.
      </Text>
      <View>
       <Menu
        visible={fieldSelector}
        onDismiss={() => setFieldSelector(false)}
        anchor={
         <Pressable onPress={() => setFieldSelector(true)}>
          <TextInput
           mode="outlined"
           label="Campo"
           right={<TextInput.Icon icon="chevron-down" onPress={() => setFieldSelector(true)} />}
           value={fieldIndex === -1 ? "" : availableFilters[fieldIndex].label}
           editable={false}
          />
         </Pressable>
        }>
        {availableFilters.map(({ label }, index) => (
         <Menu.Item
          key={index}
          onPress={() => {
           setFieldIndex(index);
           setValueIndex(-1);
           setFieldSelector(false);
          }}
          title={label}
         />
        ))}
       </Menu>

       <Menu
        visible={valueSelector}
        onDismiss={() => setValueSelector(false)}
        anchor={
         <Pressable onPress={() => setValueSelector(true)}>
          <TextInput
           mode="outlined"
           label="Valor"
           style={{ marginTop: 10 }}
           right={<TextInput.Icon icon="chevron-down" onPress={() => setValueSelector(true)} />}
           value={
            fieldIndex === -1 || valueIndex === -1
             ? ""
             : availableFilters[fieldIndex].options[valueIndex].label
           }
           editable={false}
          />
         </Pressable>
        }>
        {fieldIndex === -1
         ? null
         : availableFilters[fieldIndex].options.map((option, index) => (
            <Menu.Item
             key={index}
             onPress={() => {
              setValueIndex(index);
              setValueSelector(false);
             }}
             title={option.label}
            />
           ))}
       </Menu>
      </View>
     </Dialog.Content>
     <Dialog.Actions>
      <Button
       onPress={() => {
        setFilterBy({
         key: availableFilters[0].key,
         value: "_all_",
        });

        if (systemConfig?.saveFilterLocally)
         saveLocalStorage(
          "filter",
          JSON.stringify({ key: availableFilters[0].key, value: "_all_" })
         );

        onDismiss();
       }}>
       Cancelar/Limpar
      </Button>
      <Button
       onPress={() => {
        if (valueIndex !== -1 && fieldIndex !== -1) {
         setFilterBy({
          key: availableFilters[fieldIndex].key,
          value: availableFilters[fieldIndex].options[valueIndex].value,
         });

         if (systemConfig?.saveFilterLocally)
          saveLocalStorage(
           "filter",
           JSON.stringify({
            key: availableFilters[fieldIndex].key,
            value: availableFilters[fieldIndex].options[valueIndex].value,
           })
          );
        }
        onDismiss();
       }}>
       Filtrar
      </Button>
     </Dialog.Actions>
    </Dialog>
   </Portal>
  </>
 );
}
