import React, { useEffect, useState } from "react";
import { printToFileAsync } from "expo-print";
import { ScrollView } from "react-native";
import { Appbar } from "react-native-paper";
import { useApp } from "../App";

export default function PdfView({ navigation, route }) {
 const { setTabVisible } = useApp();
 const { html } = route.params;
 const [saving, setSaving] = useState(false);

 // esconde a tab bar
 useEffect(() => {
  setTabVisible(false);
  return () => setTabVisible(true);
 }, [setTabVisible]);

 if (!html) return null;
 return (
  <>
   {!saving && (
    <Appbar.Header>
     <Appbar.BackAction
      onPress={() => {
       navigation.goBack();
      }}
     />
     <Appbar.Content title="Gerar PDF" />
     <Appbar.Action
      disabled={saving}
      icon="content-save"
      onPress={() => {
       setSaving(true);

       let iframe = null;
       try {
        iframe = window.document.createElement("iframe");
        // set the iframe's content to be the html of the page
        iframe.srcdoc = html;
        // hide the iframe
        iframe.style.display = "none";
        // attach the iframe to the document
        window.document.body.appendChild(iframe);
       } catch (e) {
        window.toast("E001 -", e.message);
        console.error(e);
       }

       setTimeout(async () => {
        try {
         const iframeWindow = iframe.contentWindow;
         iframeWindow.print();
         iframe.remove();
        } catch (e) {
         console.error(e);
         window.toast("E002 -", e.message);
         await printToFileAsync({ html });
        }

        setTimeout(() => {
         setSaving(false);
        }, 500);
       }, 1000);
      }}
     />
    </Appbar.Header>
   )}
   <ScrollView>
    <div style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: html }} />
   </ScrollView>
  </>
 );
}
