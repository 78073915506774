import { useMemo, useRef, useState } from "react";
import { Platform, Pressable } from "react-native";
import { Menu, HelperText, TextInput, Button } from "react-native-paper";

export default function SelectInput({
 onChange,
 value,
 options,
 placeholder,
 isRequired = false,
 invalidMessage,
 icon,
}) {
 const [open, setOpen] = useState(false);
 const [localValue, setLocalValue] = useState(options.find((i) => i.value === value)?.label);

 const memoizedOptions = useMemo(
  () =>
   options.map((option, index) => (
    <Menu.Item
     key={index}
     title={option.label}
     onPress={() => {
      onChange(option.value);
      setLocalValue(option.label);
      setOpen(false);
     }}
    />
   )),
  [options, onChange]
 );

 const doOpen = useRef(() => setOpen(true));

 return (
  <>
   <Menu
    visible={open}
    onDismiss={() => setOpen(false)}
    anchor={
     <Pressable onPress={doOpen.current}>
      <TextInput
       outlineStyle={{ ...(Platform.OS === "web" && { marginTop: "2px" }) }}
       mode="outlined"
       error={!!invalidMessage}
       label={`${placeholder} ${isRequired ? "*" : ""}`}
       left={icon ? <TextInput.Icon icon={icon} onPress={doOpen.current} /> : null}
       right={<TextInput.Icon icon="chevron-down" onPress={doOpen.current} />}
       value={localValue || ""}
       editable={false}
       keyboardType="email-address"
      />
     </Pressable>
    }>
    {memoizedOptions}
   </Menu>
   <HelperText type="error" visible={!!invalidMessage}>
    {invalidMessage}
   </HelperText>
  </>
 );
}
