import { cloneDeep } from "lodash";

const cachedDefaults = {};

export default function getDefaultData(fields, cacheKey) {
 if (cacheKey && cachedDefaults[cacheKey]) return cloneDeep(cachedDefaults[cacheKey]);

 const defaultData = {};
 Object.keys(fields).forEach((key) => {
  const field = fields[key];

  if (field.defaultValue) {
   if (field.type === "date" && field.defaultValue === "today")
    defaultData[key] = new Date().toISOString();
   else defaultData[key] = field.defaultValue;
  } else {
   const t = field.type;
   if (t === "date") defaultData[key] = new Date().toISOString();
   else if (t === "datetime") defaultData[key] = null;
   else if (t === "dataList" || t === "imageList" || t === "multitext") defaultData[key] = [];
   else if (t === "file") defaultData[key] = { id: "", name: "", uri: "" };
   else defaultData[key] = "";
  }
 });

 if (cacheKey) cachedDefaults[cacheKey] = cloneDeep(defaultData);

 return cloneDeep(defaultData);
}
