import { createMask } from "imask";
import { useMemo, useState } from "react";
import { Platform } from "react-native";
import { TextInput, HelperText } from "react-native-paper";

const mask = createMask({
 mask: [
  { mask: "0000-0000" },
  { mask: "00000-0000" },
  { mask: "(00) 0000-0000" },
  { mask: "(00) 00000-0000" },
 ],
 dispatch: (appended, dynamicMasked) => {
  const length = (dynamicMasked.value + appended).length;
  if (length <= 9) return dynamicMasked.compiledMasks[0];
  if (length <= 10) return dynamicMasked.compiledMasks[1];
  if (length <= 14) return dynamicMasked.compiledMasks[2];
  return dynamicMasked.compiledMasks[3];
 },
});

export default function PhoneInput({
 onChange,
 placeholder,
 icon,
 value,
 isRequired = false,
 invalidMessage,
}) {
 const label = useMemo(() => `${placeholder} ${isRequired ? "*" : ""}`, [isRequired, placeholder]);
 const parsedIcon = useMemo(() => (icon ? <TextInput.Icon icon={icon} /> : null), [icon]);
 const [localValue, setLocalValue] = useState(value || "");

 return (
  <>
   <TextInput
    outlineStyle={{ ...(Platform.OS === "web" && { marginTop: "2px" }) }}
    mode="outlined"
    error={!!invalidMessage}
    label={label}
    left={parsedIcon}
    keyboardType="numeric"
    value={localValue}
    onChangeText={(val) => {
     val = mask.resolve(val);
     setLocalValue(val);
     onChange(val);
    }}
   />
   <HelperText type="error" visible={!!invalidMessage}>
    {invalidMessage}
   </HelperText>
  </>
 );
}
