import { nanoid } from "nanoid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Pressable, View } from "react-native";
import {
 Button,
 Card,
 HelperText,
 IconButton,
 Menu,
 Text,
 TextInput,
 useTheme,
} from "react-native-paper";
import CustomListMenu from "./CustomListMenu";

export default function AmountCustomListInput({
 onChange,
 value,
 options,
 placeholder,
 isRequired = false,
 invalidMessage,
 icon,
}) {
 const theme = useTheme();

 // criamos localData pois como estamos trabalhando com refs
 // a lista que vem de cima nem sempre estará atualizada corretamente
 const localData = useRef(null);

 // constrola quais dos itens da lista precisa receber um rerender na tela
 const [localRefresh, setLocalRefresh] = useState([]);

 const addItem = useCallback(() => {
  const newValue = [...localData.current];
  newValue.push({ name: "", amount: "" });
  onChange(newValue);
  localData.current = newValue;
  setLocalRefresh((r) => [...r, nanoid(7)]);
 }, [onChange]);

 const removeItem = useCallback(
  (index) => {
   const newValue = [...localData.current];
   newValue.splice(index, 1);
   onChange(newValue);
   localData.current = newValue;

   setLocalRefresh((arr) => {
    const nArr = [...arr];
    nArr.splice(index, 1);
    for (let i = index; i < nArr.length; i++) {
     nArr[i] = nanoid(7);
    }
    return nArr;
   });
  },
  [onChange]
 );

 const updateItem = useCallback(
  (val, index) => {
   const newValue = [...localData.current];
   newValue[index] = { ...newValue[index], ...val };
   onChange(newValue);
   localData.current = newValue;
  },
  [onChange]
 );

 useEffect(() => {
  if (localData.current) return;
  const newData = value.map((val) => val || { name: "", amount: "" });
  localData.current = newData;
  setLocalRefresh(newData.map(() => nanoid(7)));
  onChange(newData);
 }, [value, onChange]);

 const label = useMemo(() => `${placeholder} ${isRequired ? "*" : ""}`, [isRequired, placeholder]);

 if (!localData.current) return <Text style={{ color: "red" }}>Erro #92391</Text>;
 return (
  <View style={{ marginTop: 10 }}>
   <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
    <Text variant="titleMedium">{label}</Text>
    <Button
     style={{ marginLeft: 10 }}
     icon={icon || ""}
     mode="contained"
     onPress={() => addItem()}
     title="Novo">
     Novo
    </Button>
   </View>

   {invalidMessage && (
    <HelperText type="error" visible>
     {invalidMessage}
    </HelperText>
   )}

   <Card mode="elevated" style={{ marginBottom: 20 }}>
    <Card.Content>
     {localData.current.map((val, index) => (
      <View
       key={`${index}_${localRefresh[index]}`}
       style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        
        marginVertical: 5,
       }}>
       <CustomListMenu options={options} index={index} updateItem={updateItem} data={val} />
       <TextInput
        dense
        style={{
         width: 100,
         borderTopStartRadius: 0,
         borderTopEndRadius: 0,
         marginTop: 5,
         marginLeft: 5
        }}
        placeholder="Qtde."
        mode="flat"
        onChangeText={(amount) => {
         updateItem({ amount }, index);
        }}
        defaultValue={val.amount}
       />
       <IconButton
        style={{ marginLeft: 5 }}
        icon="delete-outline"
        iconColor={theme.colors.error}
        size={20}
        onPress={() => removeItem(index)}
       />
      </View>
     ))}
    </Card.Content>
   </Card>
  </View>
 );
}
