import { useCallback, useState } from "react";
import ImageInput from "./ImageInput";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text, Button, ActivityIndicator } from "react-native-paper";
import { View } from "react-native";
import useFileManager from "../hooks/useFileManager";

export default function ImageListInput({ onChange, value, placeholder }) {
 const [currentIndex, setCurrentIndex] = useState(0);
 const { uploadFile, addToDeleteQueue } = useFileManager();
 const [isUploading, setIsUploading] = useState(false);

 // guardamos dados localmente para melhorar desempenho
 const [localValue, setLocalValue] = useState(value || []);

 const addImage = useCallback(() => {
  setLocalValue((old) => {
   const newValue = [...old];
   newValue.push({
    id: null,
    uri: null,
   });
   onChange(newValue);
   setCurrentIndex((i) => {
    if (newValue.length > 1) return i + 1;
    else return 0;
   });
   return newValue;
  });
 }, [onChange]);

 const removeImage = useCallback(
  (index) => {
   setLocalValue((old) => {
    const newValue = [...old];
    const [{ id }] = newValue.splice(index, 1);
    onChange(newValue);

    if (id) addToDeleteQueue(id);

    setCurrentIndex((i) => {
     if (newValue[i] || i === 0) return i;
     else return i - 1;
    });

    return newValue;
   });
  },
  [onChange, addToDeleteQueue]
 );

 const updateImage = useCallback(
  (val, index) => {
   setLocalValue((old) => {
    const newValue = [...old];
    if (newValue[index].id) addToDeleteQueue(newValue[index].id);
    newValue[index].uri = val;
    return newValue;
   });

   setIsUploading(true);

   uploadFile(val)
    .then((obj) => {
     if (!obj?.id)
      throw new Error("tente novamente, e em caso de falha, entre em contato com o desenvolvedor.");
     setLocalValue((old) => {
      const newValue = [...old];
      newValue[index] = obj;
      onChange(newValue);
      console.log(`Upload Finalizado -> ${JSON.stringify(newValue)}`);
      return newValue;
     });
    })
    .catch((e) => window.toast(`Houve um problema ao realizar o upload: ${e.message}`))
    .finally(() => setIsUploading(false));
  },
  [onChange, uploadFile, addToDeleteQueue]
 );

 if (!value) return <Text style={{ color: "red" }}>Erro #99982</Text>;
 return (
  <>
   <Text variant="labelMedium">{placeholder}</Text>
   <View>
    {isUploading ? <ActivityIndicator /> : null}
    {localValue.length > 0 && (
     <Text variant="labelSmall" style={{ textAlign: "center" }}>
      {currentIndex + 1}/{localValue.length}
     </Text>
    )}
    <View
     style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: 5,
     }}>
     {localValue.length > 0 && localValue[currentIndex]?.uri && (
      <Button
       labelStyle={{ lineHeight: 30 }}
       compact
       disabled={localValue[currentIndex - 1] === undefined}
       onPress={() => {
        setCurrentIndex((i) => i - 1);
       }}
       contentStyle={{ height: 80 }}
       title="Left">
       <MaterialCommunityIcons name="arrow-left" size={25} />
      </Button>
     )}

     {localValue[currentIndex] !== undefined && (
      <ImageInput
       alwaysShowOnRemove
       isFromList
       value={localValue[currentIndex].uri}
       isUploading={isUploading}
       onChange={(uri) => {
        updateImage(uri, currentIndex);
       }}
       onRemove={() => {
        removeImage(currentIndex);
       }}
      />
     )}

     {(localValue[currentIndex]?.uri || !localValue?.length) && (
      <>
       {localValue[currentIndex + 1] !== undefined ? (
        <Button
         labelStyle={{ lineHeight: 30 }}
         contentStyle={{ height: 80 }}
         compact
         onPress={() => {
          setCurrentIndex((i) => i + 1);
         }}
         title="Right">
         <MaterialCommunityIcons name="arrow-right" size={25} />
        </Button>
       ) : (
        <Button
         labelStyle={{ lineHeight: 30 }}
         contentStyle={{ height: 80 }}
         compact
         onPress={addImage}
         title="Right">
         <MaterialCommunityIcons name="plus-circle" size={25} />
        </Button>
       )}
      </>
     )}
    </View>
   </View>
  </>
 );
}
