import { useMemo, useState } from "react";
import { Platform } from "react-native";
import { TextInput, HelperText } from "react-native-paper";

export default function CurrencyInput({
 onChange,
 placeholder,
 icon,
 value,
 isRequired = false,
 invalidMessage,
}) {
 const label = useMemo(() => `${placeholder} ${isRequired ? "*" : ""}`, [isRequired, placeholder]);
 const parsedIcon = useMemo(() => (icon ? <TextInput.Icon icon={icon} /> : null), [icon]);
 const [localValue, setLocalValue] = useState(value || "");

 return (
  <>
   <TextInput
    outlineStyle={{ ...(Platform.OS === "web" && { marginTop: "2px" }) }}
    mode="outlined"
    error={!!invalidMessage}
    label={label}
    left={parsedIcon}
    keyboardType="numeric"
    value={localValue}
    onChangeText={(val) => {
     // mantem os numeros apenas
     val = val.replace(/\D/g, "");

     // retirar qualquer 0 na frente
     val = parseInt(val, 10);

     // se nao for numero iremos usar uma string vazia
     if (isNaN(val)) val = "";
     else val = val.toString();

     // ajustar o valor de acordo com a quantidade de caracteres da string
     if (val.length === 1) val = `0,0${val}`;
     else if (val.length === 2) val = `0,${val}`;
     else if (val.length > 2)
      val = [val.slice(0, val.length - 2), ",", val.slice(val.length - 2)].join("");
     setLocalValue(val);
     onChange(val);
    }}
   />
   <HelperText type="error" visible={!!invalidMessage}>
    {invalidMessage}
   </HelperText>
  </>
 );
}
