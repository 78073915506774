import { useCallback, useEffect, useState } from "react";
import { Portal, Snackbar } from "react-native-paper";

export default function ToastHandler() {
 const [visible, setVisible] = useState(false);
 const [message, setMessage] = useState("");

 const onDismissSnackBar = useCallback(() => setVisible(false), []);

 useEffect(() => {
  window.toast = (description) => {
   setVisible(true);
   setMessage(description);
  };
 }, []);

 return (
  <Portal>
   <Snackbar
    visible={visible}
    onDismiss={onDismissSnackBar}
    action={{
     label: "Fechar",
     onPress: () => {
      setVisible(false);
     },
    }}>
    {message}
   </Snackbar>
  </Portal>
 );
}
