import React, { useCallback, useEffect, useMemo, useState } from "react";
import useDatabase from "../databases/FirebaseDatabase";
import { FlatList, StyleSheet, View } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import { Searchbar, useTheme, Menu, Appbar, ActivityIndicator, Text } from "react-native-paper";
import useFab from "../components/UserFab";
import FilterModal from "../components/FilterModal";
import DateFilterModal from "../components/DateFilterModal";
import { isAfter, isBefore, isEqual, isValid } from "date-fns";
import { ListCard } from "./ListView";

let cached = null;
let cachedSearchableKeys = null;
let cachedDateKey = null;

export default function ArchivedView({ navigation }) {
 const theme = useTheme();

 const { systemConfig, getArchived, isAdmin, unarchiveItems } = useDatabase();
 const [availableFilters, setAvailableFilters] = useState(null);
 const [dateFilterKey, setDateFilterKey] = useState(null);

 const [data, setData] = useState({});
 const [filterBy, setFilterBy] = useState({ key: null, value: "_all_" });
 const [filterByDate, setFilterByDate] = useState({ fromDate: null, toDate: null });

 const [availableSearchable, setAvailableSearchable] = useState([]);
 const [searchBy, setSearchBy] = useState("");

 const [parsedData, setParsedData] = useState([]);
 const [isLoading, setIsLoading] = useState(false);

 const [filterModal, setFilterModal] = useState(false);

 const [page, setPage] = useState(1);
 const [pagedData, setPagedData] = useState([]);

 // código para mostrar fab na rota
 const { showFab, addOption, removeOption } = useFab();

 useEffect(() => {
  const unsubscribe = navigation.addListener("focus", () => {
   showFab();
   setIsLoading(true);
   getArchived().then((items) => {
    setData(items);
   });
  });
  return unsubscribe;
 }, [navigation, showFab, getArchived]);

 useFocusEffect(
  useCallback(() => {
   if (isAdmin)
    addOption("remove-archived", "Desarquivar Lista", "archive-remove", () => {
     window.confirmationModal({
      title: "Desarquivar lista?",
      description: (
       <>
        Essa ação irá pegar todos os itens da lista atual (e que atendam o seu filtro atual) e irá
        desarquivá-los.
       </>
      ),
      positiveLabel: "Desarquivar",
      positiveCallback: async () => {
       if (parsedData.length) {
        unarchiveItems(parsedData).then(() => {
         setIsLoading(true);
         getArchived().then((items) => {
          setData(items);
         });
        });
       }
      },
     });
    });

   addOption(
    "view-list",
    "Ver " + systemConfig.list.listTitle || "Principal",
    "format-list-bulleted-square",
    () => {
     navigation.goBack();
    }
   );

   return () => {
    removeOption("remove-archived");
    removeOption("view-list");
   };
  }, [
   addOption,
   isAdmin,
   removeOption,
   parsedData,
   systemConfig.list.listTitle,
   navigation,
   unarchiveItems,
   getArchived,
  ])
 );

 useEffect(() => {
  async function parse() {
   let parsed = [];

   const keys = Object.keys(data);

   // Adicinamos os itens que atenderem o filtro
   keys.forEach((key) => {
    if (filterBy.value !== "_all_" && data[key][filterBy.key] !== filterBy.value) return;
    parsed.push({ ...data[key], id: key });
   });

   // Pegamos o array com o filtro e aplicamos a pesquisa
   if (availableSearchable.length > 0 && searchBy) {
    parsed = parsed.filter((item) => {
     for (let i = 0; i < availableSearchable.length; i++) {
      const { key, type } = availableSearchable[i];
      if (item[key] && item[key].toString().toLowerCase().includes(searchBy.toLowerCase()))
       return true;
     }
     return false;
    });
   }

   if (dateFilterKey && filterByDate.fromDate && filterByDate.toDate) {
    parsed = parsed.filter((item) => {
     if (!item[dateFilterKey]) return false;
     const date = new Date(item[dateFilterKey]);
     if (!isValid(date)) return false;
     if (
      (isAfter(date, filterByDate.fromDate) && isBefore(date, filterByDate.toDate)) ||
      isEqual(date, filterByDate.toDate) ||
      isEqual(date, filterByDate.fromDate)
     )
      return true;
    });
   }

   parsed.sort((a, b) => {
    if (!a.createDate) return 1;
    if (!b.createDate) return -1;
    return a.createDate > b.createDate ? -1 : 1;
   });

   setIsLoading(false);

   return parsed;
  }

  parse().then((parsed) => {
   setParsedData(parsed);
   setPage(1);
  });
 }, [data, filterBy, searchBy, availableSearchable, dateFilterKey, filterByDate]);

 useEffect(() => {
  if (parsedData.length >= (page - 1) * 20) {
   const paged = parsedData.slice(0, 20 * page);
   setPagedData(paged);
  }
 }, [page, parsedData]);

 // Gera o array de filtros e pesquisa
 useEffect(() => {
  const availableFilters = [];
  const searchableKeys = [];

  if (!cached) {
   Object.keys(systemConfig.fields).forEach((key) => {
    const field = systemConfig.fields[key];
    if (field.filterable) {
     availableFilters.push({
      label: field.label,
      key,
      options: field.options,
     });
    }
    if (field.searchable) searchableKeys.push({ key, type: field.type });
    if (field.isDateFilter) cachedDateKey = key;
   });

   cached = availableFilters;
   cachedSearchableKeys = searchableKeys;

   if (availableFilters.length > 0)
    setFilterBy({
     key: availableFilters[0].key,
     value: "_all_",
    });
  }

  setAvailableFilters(cached);
  setAvailableSearchable(cachedSearchableKeys);
  setDateFilterKey(cachedDateKey);
 }, [systemConfig]);

 const memoizedList = useMemo(() => {
  return (
   <FlatList
    style={{ backgroundColor: theme.colors.background, height: "100%" }}
    data={pagedData}
    onEndReachedThreshold={0.9}
    onEndReached={() => {
     setPage((i) => i + 1);
    }}
    renderItem={({ index, item }) => (
     <ListCard
      key={index}
      data={item}
      index={index}
      navigate={() => {
       window.confirmationModal({
        title: "Desarquivar item?",
        description: <>Para vizualisar o item, você precisará desarquivá-lo.</>,
        positiveLabel: "Sim",
        positiveCallback: async () => {
         unarchiveItems([item]).then(() => {
          navigation.navigate("Detalhes", {
           id: item.id,
          });
         });
        },
       });
      }}
     />
    )}
   />
  );
 }, [navigation, theme, pagedData, unarchiveItems]);

 // cria o input de filtros,
 const filterInput = useMemo(() => {
  if (!availableFilters || availableFilters.length === 0) return null;
  if (availableFilters.length === 1)
   return (
    <>
     <Menu
      visible={filterModal}
      onDismiss={() => setFilterModal(false)}
      anchor={
       <Appbar.Action
        icon={filterBy.value !== "_all_" ? "filter" : "filter-outline"}
        onPress={() => setFilterModal(true)}
       />
      }>
      <Menu.Item
       onPress={() => {
        setFilterBy({
         key: availableFilters[0].key,
         value: "_all_",
        });
        setFilterModal(false);
       }}
       title="Todos"
      />
      {availableFilters[0].options.map((option, index) => (
       <Menu.Item
        key={index}
        onPress={() => {
         setFilterBy({
          key: availableFilters[0].key,
          value: option.value,
         });
         setFilterModal(false);
        }}
        title={option.label}
       />
      ))}
     </Menu>
    </>
   );
  if (availableFilters.length > 1)
   return (
    <FilterModal
     availableFilters={availableFilters}
     setFilterBy={setFilterBy}
     filterBy={filterBy}
    />
   );
  return null;
 }, [filterBy, availableFilters, filterModal]);

 const dateFilterInput = useMemo(() => {
  if (!dateFilterKey) return null;
  return (
   <DateFilterModal
    filterByDate={filterByDate}
    setFilterByDate={setFilterByDate}
    fieldLabel={systemConfig.fields[dateFilterKey].label}
   />
  );
 }, [dateFilterKey, filterByDate, systemConfig]);

 return (
  <View style={{ height: "100%" }}>
   <Appbar.Header>
    <Appbar.BackAction
     onPress={() => {
      navigation.goBack();
     }}
    />
    <Appbar.Content title="Arquivados" />
    {dateFilterInput}
    {filterInput}
   </Appbar.Header>
   <View
    style={{
     padding: 20,
     backgroundColor: theme.colors.background,
    }}>
    {availableSearchable.length > 0 && (
     <Searchbar
      style={{ maxWidth: 1000, marginHorizontal: "auto", width: "100%" }}
      onChangeText={(val) => setSearchBy(val)}
      defaultValue=""
      placeholder="Pesquisar Arquivado"
     />
    )}
   </View>

   {isLoading ? (
    <View
     style={{
      padding: 20,
      backgroundColor: theme.colors.background,
      height: "100%",
     }}>
     <ActivityIndicator style={{ marginTop: 30 }} animating size="large" />
    </View>
   ) : parsedData.length ? (
    memoizedList
   ) : (
    <View
     style={{
      padding: 20,
      backgroundColor: theme.colors.background,
      height: "100%",
     }}>
     <Text style={{ textAlign: "center" }} variant="bodyLarge">
      Lista vazia...
     </Text>
    </View>
   )}
  </View>
 );
}
