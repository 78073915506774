import { addMinutes } from "date-fns";
import parseTokens from "./parseTokens";

export default function generateCalendarUrl(systemConfig, data) {
 let { event, location, duration, dateField, type, description } = systemConfig.calendarIntegration;
 if (!dateField || !duration || !event) return null;

 event = parseTokens(event, data);
 location = parseTokens(location, data);
 description = parseTokens(description, data);

 const startDate = new Date(data[dateField]);
 const endDate =
  typeof duration === "string"
   ? new Date(data[duration])
   : addMinutes(startDate, parseInt(duration, 10));

 if (type === "google") {
  let query = `&text=${event.replaceAll(" ", "+")}`;
  if (location) query += `&location=${encodeURI(location)}`;
  if (description) query += `&details=${encodeURI(description)}`;
  query += `&dates=${startDate
   .toISOString()
   .replaceAll(":", "")
   .replaceAll("-", "")
   .replaceAll(".", "")}%2F${endDate
   .toISOString()
   .replaceAll(":", "")
   .replaceAll("-", "")
   .replaceAll(".", "")}`;

  return `https://www.google.com/calendar/render?action=TEMPLATE${query}`;
 }
}
