import { createElement, useCallback, useMemo, useRef, useState } from "react";
import { Platform, Pressable } from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import { TextInput, HelperText, useTheme, Text } from "react-native-paper";
import { useApp } from "../App";
import { format } from "date-fns";

export default function DateTimeInput({
 onChange,
 placeholder,
 icon,
 value,
 isRequired = false,
 invalidMessage,
}) {
 const theme = useTheme();
 const { isDark } = useApp();
 const [dateOpen, setDateOpen] = useState(false);
 const [timeOpen, setTimeOpen] = useState(false);

 const auxValue = useRef(""); // auxiliar para o input de android
 const [localValue, setLocalValue] = useState(value ? new Date(value) : null);

 const onDateChange = useCallback(
  (event, date) => {
   setDateOpen(false);
   // se cancelou no input de data, limpamos o valor
   if (event.type === "dismissed") {
    if (onChange) {
     onChange("");
     setLocalValue(null);
    }
    return;
   }

   setTimeOpen(true);
   auxValue.current = date.toISOString().split("T")[0];
  },
  [onChange]
 );

 const onTimeChange = useCallback(
  (event, time) => {
   setTimeOpen(false);
   if (event.type === "dismissed") return;
   time.setSeconds(0);
   auxValue.current = `${auxValue.current}T${time.toTimeString().split(" ")[0]}`;
   const iso = new Date(auxValue.current).toISOString();
   if (onChange && iso !== value) {
    onChange(iso);
    setLocalValue(new Date(auxValue.current));
   }
  },
  [onChange, value]
 );

 const memoizedValue = useMemo(
  () => (localValue ? localValue.toLocaleString() : "Sem Data"),
  [localValue]
 );

 const label = useMemo(() => `${placeholder} ${isRequired ? "*" : ""}`, [isRequired, placeholder]);
 const parsedIcon = useMemo(
  () => (icon ? <TextInput.Icon icon={icon} onPress={() => setDateOpen(true)} /> : null),
  [icon]
 );

 if (Platform.OS === "web") {
  return (
   <>
    <Text variant="labelMedium">
     {placeholder} {isRequired ? "*" : ""}
    </Text>
    {createElement("input", {
     type: "datetime-local",
     value: localValue ? format(new Date(localValue), "yyyy-MM-dd'T'HH:mm") : "",
     onInput: (e) => {
      if (!e.target.value) {
       onChange("");
       setLocalValue(null);
       return;
      }
      const d = new Date(e.target.value);
      onChange(d.toISOString());
      setLocalValue(d);
     },
     style: {
      padding: "10px",
      fontSize: "16px",
      borderRadius: "4px",
      border: "1px solid rgb(121, 116, 126)",
      backgroundColor: theme.colors.background,
      color: theme.colors.onBackground,
      colorScheme: isDark ? "dark" : "light",
     },
    })}
    <HelperText type="error" visible={!!invalidMessage} style={{ marginBottom: 5 }}>
     {invalidMessage}
    </HelperText>
   </>
  );
 }

 return (
  <>
   <Pressable onPress={() => setDateOpen(true)}>
    <TextInput
     // dense
     mode="outlined"
     type={Platform.OS === "web" ? "datetime" : "text"}
     error={!!invalidMessage}
     label={label}
     left={parsedIcon}
     onChangeText={onChange}
     value={memoizedValue}
     editable={false}
    />
    <HelperText type="error" visible={!!invalidMessage}>
     {invalidMessage}
    </HelperText>
   </Pressable>
   {dateOpen && (
    <DateTimePicker value={localValue || new Date()} is24Hour onChange={onDateChange} />
   )}
   {timeOpen && (
    <DateTimePicker value={localValue || new Date()} mode="time" is24Hour onChange={onTimeChange} />
   )}
  </>
 );
}
