import React, {
 createContext,
 useCallback,
 useContext,
 useEffect,
 useMemo,
 useRef,
 useState,
} from "react";
import decideInput from "../services/decideInput";
import useDatabase from "../databases/FirebaseDatabase";
import getDefaultData from "../services/getDefaultData";
import { validate } from "../services/validator";
import useNotifications from "../hooks/useNotifications";
import { cloneDeep } from "lodash";
import { Text, Card, Appbar, useTheme } from "react-native-paper";
import { ScrollView, View } from "react-native";
import useFab from "../components/UserFab";
import useFileManager from "../hooks/useFileManager";

let cached = null;

const EditContext = createContext(null);

export default function EditView({ navigation, route }) {
 const theme = useTheme();
 const { cancelUploads, confirmUploads, isUploading } = useFileManager();
 const {
  systemConfig,
  insert,
  data: listData,
  update,
  pushTokens,
  mappedCustomLists,
 } = useDatabase();
 const { sendPushNotification } = useNotifications();
 const { id } = route.params;

 // Guardaremos os dados em uma ref, para melhorar a performance, porém precisaremos
 // recarregara  tela manualmente quando necessário usando refreshScreen
 const data = useRef(null);
 const [, refreshScreen] = useState(0);

 const [saving, setSaving] = useState(false);
 const hasUnsavedChanges = useRef(true); // para saber se o usuario está saindo da tela sem salvar
 const [invalidFields, setInvalidFields] = useState({});

 // código para mostrar fab na rota
 const { hideFab } = useFab();
 useEffect(() => {
  const unsubscribe = navigation.addListener("focus", () => {
   hideFab();
  });
  return unsubscribe;
 }, [navigation, hideFab]);

 useEffect(() => {
  const unsubscribe = navigation.addListener("beforeRemove", (_) => {
   // se estiver saindo da tela, mas salvou, iremos ignorar esse evento
   if (!hasUnsavedChanges.current) return;

   // se o usuario saiu sem salvar,
   // iremos excluir os uploads realizados durante essa edição
   cancelUploads();
  });

  return unsubscribe;
 }, [navigation, cancelUploads]);

 const updateData = useCallback((newData) => {
  Object.keys(newData).forEach((key) => {
   data.current[key] = newData[key];
  });
 }, []);

 const saveData = useCallback(async () => {
  if (isUploading > 0) return;
  const valid = await validate(data.current);
  if (!valid.success) {
   window.toast("Dados Inválidos: " + valid.message);
   console.log(valid.invalidFields);
   setInvalidFields(valid.invalidFields);
   return;
  }
  setSaving(true);
  try {
   // confirmar uploads
   // Confirmamos antes de salvar para garantir que as imagens não serão excluídas
   // Isso pode causar um problema caso o item não seja salvo, e as fotos serao mantidas, porém é melhor do que perder as fotos
   confirmUploads();

   if (!id) {
    const body = cloneDeep(data.current);
    await insert(body);
    Object.keys(systemConfig.fields).forEach((key) => {
     const field = systemConfig.fields[key];
     if (field.type === "user" && field.enableNotifications) {
      if (pushTokens[body[key]]) {
       sendPushNotification(pushTokens[body[key]], field.notification);
      }
     }
    });
   } else await update(id, data.current);

   hasUnsavedChanges.current = false;

   setSaving(false);
   navigation.goBack();
  } catch (e) {
   throw e;
  }
 }, [
  isUploading,
  insert,
  navigation,
  id,
  update,
  pushTokens,
  sendPushNotification,
  systemConfig.fields,
  confirmUploads,
 ]);

 // Gera os dados padroes
 useEffect(() => {
  if (data.current) return;

  const defaultData = getDefaultData(systemConfig.fields, "mainpage");
  if (id) {
   data.current = {
    ...defaultData,
    ...listData[id],
   };
  } else {
   data.current = defaultData;
  }

  refreshScreen((i) => i + 1);
 }, [systemConfig, id, listData]);

 const mappedFields = useMemo(() => {
  if (cached) return cached;
  const fieldsByCategory = {
   nocat: [], // para campos sem categoria
  };
  Object.keys(systemConfig.fields).forEach((key) => {
   const field = systemConfig.fields[key];
   field.key = key; // jogamos o o identificador do field pra dentro do objeto
   if (!field.category) {
    fieldsByCategory.nocat.push(systemConfig.fields[key]);
   } else {
    if (!fieldsByCategory[field.category]) fieldsByCategory[field.category] = [];
    fieldsByCategory[field.category].push(systemConfig.fields[key]);
   }
  });
  cached = fieldsByCategory;
  return fieldsByCategory;
 }, [systemConfig]);

 if (!data.current) return null;
 return (
  <EditContext.Provider value={{ invalidFields }}>
   <Appbar.Header>
    <Appbar.BackAction
     onPress={() => {
      navigation.goBack();
     }}
    />
    <Appbar.Content title={id ? "Editar" : "Novo"} />
    <Appbar.Action disabled={!!isUploading || saving} icon="content-save" onPress={saveData} />
   </Appbar.Header>
   <ScrollView style={{ backgroundColor: theme.colors.background, padding: 15 }}>
    {Object.keys(mappedFields).map((category, index) => {
     if (mappedFields[category].length === 0) return null;
     return (
      <View key={index} style={{ maxWidth: 1000, marginHorizontal: "auto", width: "100%" }}>
       <View
        style={{
         paddingHorizontal: 15,
         paddingVertical: 5,
         backgroundColor: theme.colors.surfaceVariant,
         borderTopLeftRadius: 4,
         borderTopRightRadius: 4,
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
         marginLeft: 10,
         alignSelf: "flex-start",
        }}>
        <Text
         variant="labelLarge"
         style={{ fontWeight: "600", color: theme.colors.onSurfaceVariant }}>
         {category === "nocat" ? "Outros" : category}
        </Text>
       </View>
       <Card mode="contained" style={{ marginBottom: 30 }} key={index}>
        <Card.Content>
         {mappedFields[category].map((field) => {
          return decideInput(
           field,
           data.current,
           updateData,
           invalidFields[field.key],
           mappedCustomLists
          );
         })}
        </Card.Content>
       </Card>
      </View>
     );
    })}
   </ScrollView>
  </EditContext.Provider>
 );
}

export function useEdit() {
 return useContext(EditContext);
}
