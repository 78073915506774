import { useEffect } from "react";
import { Image, View } from "react-native";
import logo from "../assets/logo.png";
import useFab from "../components/UserFab";
import useDatabase from "../databases/FirebaseDatabase";
import { Text } from "react-native-paper";

export default function SplashScreen({ navigation }) {
 const { logoUrl, isClientDisabled } = useDatabase();

 // código para mostrar fab na rota
 const { hideFab } = useFab();
 useEffect(() => {
  const unsubscribe = navigation.addListener("focus", () => {
   hideFab();
  });
  return unsubscribe;
 }, [navigation, hideFab]);

 return (
  <View
   style={{
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2e2e2e",
   }}>
   {logoUrl ? (
    <View style={{ padding: 20, backgroundColor: "white", borderRadius: 10 }}>
     <Image
      style={{ width: 150, height: 150, resizeMode: "contain" }}
      accessibilityLabel="Logo"
      source={{ uri: logoUrl }}
     />
    </View>
   ) : (
    <Image
     style={{ width: 150, height: 150, resizeMode: "contain" }}
     accessibilityLabel="Logo"
     source={logo}
    />
   )}
   {isClientDisabled && (
    <Text style={{ color: "red", fontSize: 14, textAlign: "center", marginTop: 20, padding: 10 }}>
     Seu sistema foi desativado. Entre em contato pelo email rosethorngames2018@gmail.com ou pelo
     WhatsApp no número (31) 99613-2576 para mais informações
    </Text>
   )}
  </View>
 );
}
