import { addMinutes, startOfMonth } from "date-fns";
import { createElement, useCallback, useEffect, useMemo, useState } from "react";
import { Platform, Pressable, View } from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import { Appbar, Button, Dialog, Portal, Text, TextInput, useTheme } from "react-native-paper";
import { useApp } from "../App";

export default function DateFilterModal({
 filterByDate,
 setFilterByDate,
 fieldLabel = "Desconhecido",
}) {
 const theme = useTheme();
 const { isDark } = useApp();

 const [modalOpen, setModalOpen] = useState(false);
 const [fromDate, setFromDate] = useState(null);
 const [toDate, setToDate] = useState(null);
 const [openFromDate, setOpenFromDate] = useState(false);
 const [openToDate, setOpenToDate] = useState(false);

 const onDismiss = useCallback(() => {
  setOpenFromDate(false);
  setOpenToDate(false);
  setModalOpen(false);
 }, []);

 // Inciar as variaveis fromDate e toDate com base no filtro existente
 useEffect(() => {
  if (!modalOpen) return;
  if (filterByDate?.toDate) setToDate(filterByDate.toDate);
  else setToDate(new Date());
  if (filterByDate?.fromDate) setFromDate(filterByDate.fromDate);
  else setFromDate(startOfMonth(new Date()));
 }, [filterByDate, modalOpen]);

 const inputs = useMemo(() => {
  if (Platform.OS === "web") {
   return (
    <View>
     <Text variant="labelMedium">Do dia</Text>
     {createElement("input", {
      type: "date",
      value: fromDate
       ? `${fromDate.getFullYear()}-${("0" + (fromDate.getMonth() + 1)).slice(-2)}-${(
          "0" + fromDate.getDate()
         ).slice(-2)}`
       : "",
      onInput: (e) => {
       if (!e.target.value) {
        setFromDate(null);
        return;
       }

       let d = new Date(e.target.value);

       // ajustando para exatamente meia noite do fuso horário do navegador
       d = addMinutes(d, d.getTimezoneOffset());

       setFromDate(d);
      },
      style: {
       padding: "10px",
       fontSize: "16px",
       borderRadius: "4px",
       border: "1px solid rgb(121, 116, 126)",
       backgroundColor: theme.colors.background,
       color: theme.colors.onBackground,
       colorScheme: isDark ? "dark" : "light",
      },
     })}
     <Text style={{ marginTop: 10 }} variant="labelMedium">
      Até o dia
     </Text>
     {createElement("input", {
      type: "date",
      value: toDate
       ? `${toDate.getFullYear()}-${("0" + (toDate.getMonth() + 1)).slice(-2)}-${(
          "0" + toDate.getDate()
         ).slice(-2)}`
       : "",
      onInput: (e) => {
       if (!e.target.value) {
        setToDate(null);
        return;
       }

       let d = new Date(e.target.value);

       // ajustando para exatamente meia noite do fuso horário do navegador
       d = addMinutes(d, d.getTimezoneOffset());

       setToDate(d);
      },
      style: {
       padding: "10px",
       fontSize: "16px",
       borderRadius: "4px",
       border: "1px solid rgb(121, 116, 126)",
       backgroundColor: theme.colors.background,
       color: theme.colors.onBackground,
       colorScheme: isDark ? "dark" : "light",
      },
     })}
    </View>
   );
  } else {
   return (
    <View>
     <Pressable onPress={() => setOpenFromDate(true)}>
      <TextInput
       outlineStyle={{...(Platform.OS === 'web' && {marginTop: '2px'})}}
       mode="outlined"
       label="Do dia"
       //  left={parsedIcon}
       style={{ marginBottom: 10 }}
       onChangeText={() => {}}
       value={fromDate ? fromDate.toLocaleDateString() : "Sem Data"}
       editable={false}
      />
     </Pressable>
     <Pressable onPress={() => setOpenToDate(true)}>
      <TextInput
       outlineStyle={{...(Platform.OS === 'web' && {marginTop: '2px'})}}
       mode="outlined"
       label="Até o dia"
       //  left={parsedIcon}
       onChangeText={() => {}}
       value={toDate ? toDate.toLocaleDateString() : "Sem Data"}
       editable={false}
      />
     </Pressable>
     {openFromDate && (
      <DateTimePicker
       dateFormat="shortdate"
       value={fromDate}
       mode="date"
       is24Hour
       onChange={(_, date) => {
        setOpenFromDate(false);
        setFromDate(date);
       }}
      />
     )}
     {openToDate && (
      <DateTimePicker
       dateFormat="shortdate"
       value={toDate}
       mode="date"
       is24Hour
       onChange={(_, date) => {
        setOpenToDate(false);
        setToDate(date);
       }}
      />
     )}
    </View>
   );
  }
 }, [fromDate, isDark, toDate, openFromDate, openToDate, theme.colors]);

 return (
  <>
   <Appbar.Action
    icon={filterByDate.fromDate ? "calendar-remove" : "calendar-search"}
    onPress={() => setModalOpen(true)}
   />
   <Portal>
    <Dialog
     style={{ width: "80%", maxWidth: 500, marginHorizontal: 30, alignSelf: "center" }}
     visible={modalOpen}
     onDismiss={onDismiss}>
     <Dialog.Title>Filtrar Data</Dialog.Title>
     <Dialog.Content>
      <Text style={{ marginBottom: 15 }} variant="labelMedium">
       Selecione abaixo um período, e listaremos apenas itens que possuírem o campo "{fieldLabel}"
       entre as duas datas selecionadas
      </Text>
      {inputs}
     </Dialog.Content>
     <Dialog.Actions>
      <Button
       onPress={() => {
        setFilterByDate({ fromDate: null, toDate: null });
        onDismiss();
       }}>
       Cancelar/Limpar
      </Button>
      <Button
       onPress={() => {
        if (fromDate && toDate) setFilterByDate({ fromDate, toDate });
        onDismiss();
       }}>
       Filtrar
      </Button>
     </Dialog.Actions>
    </Dialog>
   </Portal>
  </>
 );
}
